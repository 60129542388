import React from 'react'
import styled from 'styled-components'
import { CopyBlock, dracula } from 'react-code-blocks'

const CodeBlock = ({ response, request }) => {
    return(
        <>
            <CodeContainer className='code-block'>
                <Label>Request:</Label>
                <CopyBlock
                    text={request}
                    language='js'
                    showLineNumbers={false}
                    theme={dracula}
                    wrapLongLines
                    codeBlock
                />
            </CodeContainer>

            <CodeContainer className='code-block'>
                <Label>Response:</Label>
                <CopyBlock
                    text={response}
                    language='js'
                    showLineNumbers={false}
                    theme={dracula}
                    wrapLongLines
                    codeBlock
                />
            </CodeContainer>
        </>
    )
}

export default CodeBlock


const CodeContainer = styled.div`
    padding: 0 2rem 1rem 2rem;
    font-size: 1.3em;
    text-align: left;
    max-width: 70rem;
    code {
        width: 40rem;
        min-height: 4rem;
        max-height: 30rem;
    }
`

const Label = styled.label`
    font-size: 1.4em;
    margin-right: 2rem;
`
