import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import Accordion from './Accordion'
import CodeBlock from './CodeBlock'
import { Context } from './Tutorial'
import { AppContext } from '../../App'
import { addPersonReq, formatRes } from './codeTemplates'
import { SANDBOX_URL } from '../../services/utils'

export default function Person() {
    const { token } = useContext(Context)
    const { setAlert, setMessage } = useContext(AppContext)

    // POST - /persons
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [response, setReponse] = useState({
        status: '',
        data: {}
    })

    const addPerson = async () => {
        if (!token) {
            setAlert({ type: 0 })
            setMessage('JWT required, please use the POST /login request.')
            return
        }
        try {
            const res = await fetch(`${SANDBOX_URL}/persons`, {
                method: 'POST',
                headers: new Headers({
                    'Authorization': `Bearer ${token}`
                }),
                body: JSON.stringify({
                    email,
                    first_name: firstName,
                    last_name: lastName
                }),
            })
            setReponse({
                status: res.status,
                data: await res.json()
            })
        }
        catch(e) {
            console.log('error', e)
        }
    }

    const responseText = () => {
        if (!response.status) {
            return <></>
        }
        if (response.status < 299) {
            return(
                <Text>
                    Perfect. You will need the Person ID that's in the
                    response, so copy it right now.
                </Text>
            )
        }
        return(
            <Text>
                You must have a typo in the input, make sure it is correct
                (it's case sensitive), then try again.
            </Text>
        )
    }

    return (
        <Accordion title='POST - /persons' heightRef={response}>
            <Text>
                The first step is to add a consumer - that is, a Person - to your profile.

                In the sandbox you can use any of the following:
            </Text>
            <TableContainer>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Email
                            </th>
                            <th>
                                First Name
                            </th>
                            <th>
                                Last Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                consumer_1@pentadatainc.com
                            </td>
                            <td>
                                Consumer
                            </td>
                            <td>
                                One
                            </td>
                        </tr>
                        <tr>
                            <td>
                                consumer_2@pentadatainc.com
                            </td>
                            <td>
                                Consumer
                            </td>
                            <td>
                                Two
                            </td>
                        </tr>
                        <tr>
                            <td>
                                consumer_3@pentadatainc.com
                            </td>
                            <td>
                                Consumer
                            </td>
                            <td>
                                Three
                            </td>
                        </tr>
                        <tr>
                            <td>
                                consumer_4@pentadatainc.com
                            </td>
                            <td>
                                Consumer
                            </td>
                            <td>
                                Four
                            </td>
                        </tr>
                    </tbody>
                </table>
            </TableContainer>
            <Row style={{ margin:'1rem', height: 'auto' }}>
                <Column>
                    <InputRow>
                        <Label>email:</Label>
                        <Input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </InputRow>
                    <InputRow>
                        <Label>first_name:</Label>
                        <Input
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </InputRow>
                    <InputRow>
                        <Label>last_name:</Label>
                        <Input
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            onKeyDown={e => e.key === 'Enter' && addPerson()}
                        />
                    </InputRow>
                </Column>
                <InputRow style={{ margin: '.55rem 1rem', alignSelf: 'flex-end' }}>
                    <Button onClick={() => addPerson()}>
                        Send
                    </Button>
                </InputRow>
            </Row>
            <CodeBlock
                request={addPersonReq({ firstName, lastName, email })}
                response={formatRes(response)}
            />
            {responseText()}
        </Accordion>
    )
}

const Row = styled.div`
    display: flex;
    align-items: center;
`

const InputRow = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: .5rem 1rem;
    width: 25rem;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const Input = styled.input`
    width: 15rem;
    height: 1rem;
    font-size: 1.2em;
`

const Label = styled.label`
    font-size: 1.4em;
    margin-right: 2rem;
`

const Button = styled.button`
    background-color: ${({ theme }) => theme.colors.pentaPrimary };
    min-width: 5rem;
    display: flex;
    justify-content: center;
`
const Text = styled.div`
    font-size: 1.2em;
    margin: 1rem 0;
    text-align: left;
    padding: 0 2rem;
    max-width: 60rem;
`

const TableContainer = styled.div`
    max-width: 60rem;
    margin: 1rem 2rem;
`
