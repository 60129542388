import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export const BASE_URL = process.env.REACT_APP_ENV === 'production' 
  ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_STAGE_URL

export const FINICITY_WEBHOOK_URL = process.env.REACT_APP_ENV === 'production'
  ? process.env.REACT_APP_FINICITY_WEBHOOK_PROD : process.env.REACT_APP_FINICITY_WEBHOOK_STAGE

export const SANDBOX_URL = process.env.REACT_APP_SANDBOX_URL

export const getBaseURL = (environment) => {
  // sandbox: 0, prod/stage: 1
  if (environment === 0) {
    return SANDBOX_URL;
  }
  else {
    return BASE_URL;
  }
}

export const isTokenValid = (token) => {
  return token === 'valid' ? true : false
}

export const formatDate = (date) => {
  return date = moment(date).format('MM/DD/YYYY')
 }

export const formatDateMonths = (date) => {
  return date = moment(date, 'YYYYMMDD').format('MMMM DD, YYYY')
 }

export const formatDateSlash = (date) => {
  return date = moment(date, 'YYYYMMDD').format('MM/DD/YYYY')
 }

export const getRNG = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
}

export const formatTUDate = (date) => {
  return moment(date).format('YYYY-MM-DD')
}

export const getCompanies = (data) => {
  let list = [];
  data.accounts.forEach(({ company, accountId, useCases, optIn }) => {
    if (!list.some(d => d.company === company)) {
      let temp = {
        company,
        id: accountId,
        useCases
      }
      list.push(temp);
    }
  })
  data.cards.forEach(({ company, cardId, useCases, optIn }) => {
    if (!list.some(d => d.company === company)) {
      let temp = {
        company,
        id: cardId,
        useCases
      }
      list.push(temp);
    }
  })
  return list;
};

export const filterOptOut = (data) => {
  let list = {accounts: [], cards: []};
  data.accounts.forEach(({ company, bank, accountId, optIn, subscriberId }) => {
    if (optIn) {
      let temp = {
        company,
        bank,
        accountId,
        subscriberId,
        optIn
      }
      list.accounts.push(temp);
    }
  })
  data.cards.forEach(({ company, lastFour, cardId, optIn, subscriberId }) => {
    if (optIn) {
      let temp = {
        company,
        lastFour,
        cardId,
        subscriberId,
        optIn
      }
      list.cards.push(temp);
    }
  })
  return list;
};

export const countIsActive = (data) => {
  let count = 0;
  for(const d in data) {
    if(data[d].is_active) {
      count++;
    }
  }
  return count;
}

export const USER_TYPES = {
  CONSUMER: "consumer",
  SUBSCRIBER: "subscriber"
}

export const isUserSubscriber = (userType) => {
  return userType === USER_TYPES.SUBSCRIBER ? true : false;
}

export const filterTrackingData = (data) => {
  let list = [];
  data.accounts.forEach(({ company, accountId, bank, consent, subscriberId, optIn, lastOptIn }) => {
    if (!list.some(d => d.id === subscriberId)) {
      let temp = {
        company,
        id: subscriberId,
        data: [
          { type: 'bank', account: bank, id: accountId, consent, optIn, lastOptIn }
        ]
      }
      list.push(temp);
    } else {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === subscriberId) {
          let temp = {
            type: 'bank', account: bank, id: accountId, consent, optIn, lastOptIn
          }
          list[i].data.push(temp);
          break;
        }
      }
    }
  })
  data.cards.forEach(({ company, cardId, lastFour, consent, subscriberId, lastOptIn, optIn }) => {
    if (!list.some(d => d.id === subscriberId)) {
      let temp = {
        company,
        id: subscriberId,
        data: [
          { type: 'card', account: lastFour, id: cardId, consent, optIn, lastOptIn }
        ]
      }
      list.push(temp);
    } else {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === subscriberId) {
          let temp = {
            type: 'card', account: lastFour, id: cardId, consent, optIn, lastOptIn
          }
          list[i].data.push(temp);
          break;
        }
      }
    }
  })
  return list;
};

export const buildTrackingData = ({ accounts=[], income=[] }) => {
  const list = [];
  accounts.forEach(({ company, lastOptIn }) => {
    list.push({
      company,
      lastOptIn,
      type: 'Bank',
      key: uuidv4()
    })
  })
  income.forEach(({ company, lastOptIn }) => {
    list.push({
      company,
      lastOptIn,
      type: 'Credit/Income Information',
      key: uuidv4()
    })
  })
  return list;
};