import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import SideNav from '../subscriber/SideNav';
import Loading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar, faTrashAlt, faCheck, faCreditCard, faUniversity } from '@fortawesome/free-solid-svg-icons'
import { modalStyles, selectStyles } from '../../Theme';
import Modal from 'react-modal';
import { BASE_URL } from '../../services/utils'
import isLoading from '../common/isLoading';
import Select from 'react-select';
import {
	SquarePaymentForm,
	CreditCardNumberInput,
	CreditCardExpirationDateInput,
	CreditCardPostalCodeInput,
	CreditCardCVVInput,
} from 'react-square-payment-form';
import 'react-square-payment-form/lib/default.css';
import CustomPayButton from '../common/CustomPayButton';

const Billing = ({
	view, handleView, dashboardData, setMessage, message, getToken, setLoading,
	setAlert, fetchSubscriberData, setErrorStatus, fetchCardsData, fetchAccountsData
}) => {
	const [addPayment, setAddPayment] = useState(false);
	const [method, setMethod] = useState({ value: 'bank', label: 'Bank' });
	const [verifyAch, setVerifyAch] = useState(false);
	const [routingNo, setRoutingNo] = useState('');
	const [accountNo, setAccountNo] = useState('');
	const [accountNo2, setAccountNo2] = useState('');
	const [amt1, setAmt1] = useState('');
	const [amt2, setAmt2] = useState('');
	const [errorMessages, setErrorMessages] = useState([]);
	const [update, setUpdate] = useState(false);
	const [confirmDeleteCard, setConfirmDeleteCard] = useState(false);
	const [defaultBilling, setDefaultBilling] = useState(null);
	const [currentCard, setCurrentCard] = useState(false);
	const [currentAccount, setCurrentAccount] = useState(false);
	
	//Modals
	const [openModalNotification, setOpenModalNotification] = useState(false);
	const [modalMessage, setModalMessage] = useState('');
	const [openModalErr, setOpenModalErr] = useState(false);
	const [openMakeDefaultModal, setOpenMakeDefaultModal] = useState(false);
	const [openCardModalConfirm, setOpenCardModalConfirm] = useState(false);
	const [openAccountModalConfirm, setOpenAccountModalConfirm] = useState(false);

	const methodOptions = [
		{ value: 'bank', label: 'Bank' },
		{ value: 'card', label: 'Card' }
	]

	const getDefaultBilling = (data) => {
		for (let p of data) {
			if (p.type === 'bank') {
				return p;
			}
		}
		return data[0];
	}

	useEffect(() => {
		if (dashboardData) {
			if (dashboardData.billing) {
				setDefaultBilling(getDefaultBilling(dashboardData.billing))
			}
		}
	}, [dashboardData, defaultBilling])

	const addAchPayment = useCallback(async () => {
		setMessage('');
		if (!accountNo || !routingNo) {
			setMessage("Account and routing #'s required");
			return;
		}
		if (accountNo !== accountNo2) {
			setMessage("Account #'s do not match");
			return;
		}
		setLoading(true);
		try {
			const { token } = await getToken();
			if (!token) return null;
			const res = await fetch(`${BASE_URL}/subscribers/ach`, {
				method: 'POST',
				headers: new Headers({
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				}),
				body: JSON.stringify({
					account_no: accountNo,
					routing_no: routingNo
				})
			})
			if (res.status === 201) {
				setMessage('Payment added. Please check your account and verify amounts.');
				setAlert({ type: 0 })
				setAddPayment(false);
				fetchSubscriberData({ token, environment: 1 });
			} else {
				setMessage('There was a problem adding payment method. Please try again or contact support');
				setAlert({ type: 0, contact: true });
				setAddPayment(false);
			}
		}
		catch (e) {
			setAlert({ type: 1 });
			console.log(e)
		}
		finally {
			setLoading(false);
			setAccountNo('');
			setAccountNo2('');
			setRoutingNo('');
		}
	}, [getToken, setAlert, setMessage, setLoading, accountNo, accountNo2, routingNo, fetchSubscriberData])

	const makeDefault = useCallback(async (payment_id) => {
		setMessage('');
		
		setLoading(true);
		try {
			const { token } = await getToken();
			if (!token) return null;
			const res = await fetch(`${BASE_URL}/subscribers/ach/${payment_id}/default`, {
				method: 'PUT',
				headers: new Headers({
					'Authorization': `Bearer ${token}`
				})
			})
			if (res.status === 200) {
				setMessage('Payment added. Please check your account and verify amounts');
				setAlert({ type: 0 })
				fetchSubscriberData({ token, environment: 1 });
			} else {
				setMessage('There was a problem adding payment method. Please try again or contact support');
			}
		}
		catch (e) {
			setAlert({ type: 1 });
			console.log(e)
		}
		finally {
			setLoading(false);
		}
	}, [getToken, setAlert, setMessage, setLoading, fetchSubscriberData])

	const verifyAchPayment = useCallback(async () => {
		setMessage('');
		if (!amt1 || !amt2) {
			setMessage('Amounts required');
			return;
		}
		setLoading(true);
		try {
			const { token } = await getToken();
			if (!token) return null;
			const res = await fetch(`${BASE_URL}/subscribers/ach-verify`, {
				method: 'POST',
				headers: new Headers({
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				}),
				body: JSON.stringify({
					payment_id: currentAccount.payment_id,
					amount1: parseInt(amt1),
					amount2: parseInt(amt2)
				})
			})
			switch (res.status) {
				case 200:
					setMessage('Payment verified.');
					setAlert({ type: 0 })
					setVerifyAch(false);
					fetchSubscriberData({ token, environment: 1 });
					break;

				case 403:
					setMessage('Incorrect amounts.');
					break;

				default:
					setMessage('Not able to verify. Make sure payment has been added.')
					setAlert({ type: 0 });
					setVerifyAch(false);
			}
		}
		catch (e) {
			setAlert({ type: 1 });
			console.log(e)
		}
		finally {
			setLoading(false);
			setAmt1('');
			setAmt2('');
			setRoutingNo('');
		}
	}, [getToken, setAlert, setMessage, setLoading, amt1, amt2, fetchSubscriberData, currentAccount])

	const deleteCard = useCallback(async (payment_id) => {
		setMessage('');
		setLoading(true);
		try {
			const { token } = await getToken();
			if (!token) return null;

			const res = await fetch(`${BASE_URL}/subscribers/billing`, {
				method: 'DELETE',
				headers: new Headers({
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				}),
				body: JSON.stringify({
					paymentId: payment_id
				})
			})
			switch (res.status) {
				case 200:
					setMessage('Card Deleted.');
					fetchSubscriberData({ token, environment: 1 });
					break;

				case 401:
					setMessage('Not abe to remove. Please add another form of payment to remove.');
					setAlert({ type: 0 });
					break;

				default:
					setMessage('Please get in touch for help.')
					setAlert({ type: 0 });
			}
		}
		catch (e) {
			setAlert({ type: 1 });
			console.log(e)
		}
		finally {
			setLoading(false);
			setCurrentCard(null);
		}
	}, [getToken, setAlert, setMessage, setLoading, fetchSubscriberData])

	const deleteAccount = useCallback(async (payment_id) => {
		setMessage('');
		setLoading(true);
		try {
			const { token } = await getToken();
			if (!token) return null;

			const res = await fetch(`${BASE_URL}/subscribers/ach/${payment_id}`, {
				method: 'DELETE',
				headers: new Headers({
					'Authorization': `Bearer ${token}`
				})
			})
			switch (res.status) {
				case 200:
					setMessage('Account Deleted.');
					fetchSubscriberData({ token, environment: 1 });
					break;

				default:
					setMessage('Please get in touch for help.')
			}
		}
		catch (e) {
			setAlert({ type: 1 });
			console.log(e)
		}
		finally {
			setLoading(false);
			setCurrentCard(null);
		}
	}, [getToken, setAlert, setMessage, setLoading, fetchSubscriberData])

	const addCardPayment = useCallback(async ({ nonce, cardData, token }) => {
		try {
			const res = await fetch(`${BASE_URL}/subscribers/billing`, {
				method: 'POST',
				headers: new Headers({
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				}),
				body: JSON.stringify({
					cardNonce: nonce,
					postalCode: cardData.billing_postal_code
				})
			})
			if (res.status === 201) {
				return true;
			} else {
				return false;
			}
		}
		catch (e) {
			setAlert({ type: 1 });
			console.log(e)
		}
	}, [setAlert])

	const submitCardPayment = useCallback(async ({ nonce, cardData }) => {
		setLoading(true);
		try {
			const { token } = await getToken();
			if (!token) return;
			const res = await addCardPayment({ nonce, cardData, token });
			if (res) {
				setMessage('Payment successfuly added.');
				setAlert({ type: 0 });
				setAddPayment(false);
				fetchSubscriberData({ token, environment: 1 });
			} else {
				setMessage('Not able to add payment. Please try again or contact support.');
				setAlert({ type: 0, contact: true });
			}
		}
		catch (e) {
			setAlert({ type: 1 });
			console.log(e)
		}
		finally {
			setLoading(false);
		}
	}, [setLoading, setAlert, getToken, fetchSubscriberData, setMessage, addCardPayment])

	const deleteCardPayment = useCallback(async ({ token }) => {
		try {
			const res = await fetch(`${BASE_URL}/subscribers/billing`, {
				method: 'DELETE',
				headers: new Headers({
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				}),
				body: JSON.stringify({
					paymentId: Object.keys(defaultBilling.payment_id)
				})
			})
			if (res.status !== 200) {
				return false;
			}
			return true;
		}
		catch(e) {
			setAlert({ type: 0 });
			console.log('Error deleting card', e)
		}
	}, [setAlert, defaultBilling])

	const submitDeleteCard = useCallback(async () => {
		setLoading(true);
		try {
			const { token } = await getToken();
			if (!token) return;
			const del = await deleteCardPayment({ token });
			if (!del) {
				setAlert({ type: 0, contact: true });
				setMessage('There was a problem removing your payment method. Please try again or contact support');
				return;
			}
			setMessage('Payment method removed.');
			setAlert({ type: 0 });
			fetchSubscriberData({ token, environment: 1 });
			return;
		}
		catch (e) {
			setAlert({ type: 1 });
			console.log('Error deleting card payment', e)
		}
		finally {
			setLoading(false);
		}
	}, [setLoading, setAlert, getToken, setMessage, fetchSubscriberData, deleteCardPayment])

	const updateCardPayment = useCallback(async ({ nonce, cardData }) => {
		setUpdate(false);
		setLoading(true);
		try {
			const { token } = await getToken();
			if (!token) return;
			// Need to add check here for bank/card	
			const del = await deleteCardPayment({ nonce, cardData, token });
			if (!del) {
				setMessage('There was a problem updating payment. Please try again or contact support.');
				setAlert({ type: 0, contact: true });
				return;
			}
			const res = await addCardPayment({ nonce, cardData, token });
			if (!res) {
				setMessage('There was a problem updating payment. Please try again or contact support.');
				setAlert({ type: 0, contact: true });
				return;
			}
			setMessage('Payment method updated.');
			setAlert({ type: 0 });
			fetchSubscriberData({ token, environment: 1 });
			setAddPayment(false);
			return;
		}
		catch (e) {
		  setAlert({ type: 1 });
		  console.log(e)
		}
		finally {
			setLoading(false);
		}
	  }, [setLoading, setAlert, getToken, setMessage, fetchSubscriberData, addCardPayment, deleteCardPayment])

	const cardNonceResponseReceived = useCallback((errors, nonce, cardData, buyerVerificationToken) => {
		if (errors) {
			setErrorMessages(errors.map(error => error.message))
			return
		}
		setErrorMessages([]);
		update ? updateCardPayment({ nonce, cardData }) : submitCardPayment({ nonce, cardData });
	}, [submitCardPayment, update, updateCardPayment])

	const createVerificationDetails = useCallback(() => {
		return {
			currencyCode: "USD",
			intent: "STORE",
			billingContact: {
				familyName: dashboardData.firstName,
				givenName: dashboardData.lastName,
				email: dashboardData.email
			}
		}
	}, [dashboardData])

	const handleCancelSquare = useCallback(() => {
		setAddPayment(false);
		setErrorMessages([]);
		// setNonceData({});
	}, [])

	const squareForm = () => {
		return (
			<>
				<SquarePaymentForm
					sandbox={false} //TRUE WHEN USING SANDBOX KEYS
					applicationId={process.env.REACT_APP_SQ_APP_ID}
					locationId={process.env.REACT_APP_SQ_LOCATION_ID}
					cardNonceResponseReceived={cardNonceResponseReceived}
					createVerificationDetails={createVerificationDetails}
					inputStyles={[{ lineHeight: '30px', padding: '5px 5px 5px 10px' }]}
					style={{ padding: '2rem' }}
				>
					<fieldset className="sq-fields">
						<Row style={{ justifyContent: 'left' }}>
							<CardInput style={{ width: '13rem', marginRight: '1rem' }}>
								<CreditCardNumberInput />
							</CardInput>
							<CardInput style={{ width: '6rem' }}>
								<CreditCardExpirationDateInput />
							</CardInput>
						</Row>
						<Row style={{ justifyContent: 'left', marginTop: '1rem' }}>
							<CardInput style={{ width: '5rem', marginRight: '1rem' }}>
								<CreditCardCVVInput />
							</CardInput>
							<CardInput style={{ width: '6rem' }}>
								<CreditCardPostalCodeInput />
							</CardInput>
						</Row>
					</fieldset>
					<Row style={{ justifyContent: 'center' }}>
						<Button style={{ marginRight: '1rem' }} onClick={handleCancelSquare}>Cancel</Button>
						<CustomPayButton text={'Submit'} />
					</Row>
				</SquarePaymentForm>
				{<div className="sq-error-message">
					{errorMessages && errorMessages.map(errorMessage =>
						<Li key={`sq-error-${errorMessage}`}>{errorMessage}</Li>
					)}
				</div>}
			</>
		)
	}

	const achForm = () => {
		return (
			<>
				<Message style={{ maxWidth: '100%', marginTop: '1rem' }}>Bank account information:</Message>
				<Row style={{ marginTop: '1rem', justifyContent: 'flex-start' }}>
					<Input
						value={routingNo}
						name='Routing No'
						autoComplete='off'
						placeholder='Routing #'
						style={{ width: '8rem' }}
						onChange={(e) => setRoutingNo(e.target.value)}
					/>
				</Row>
				<Row style={{ marginTop: '1rem', justifyContent: 'flex-start' }}>
					<Input
						value={accountNo}
						name='Account No'
						autoComplete='off'
						placeholder='Account #'
						style={{ width: '9rem' }}
						onChange={(e) => setAccountNo(e.target.value)}
					/>
					<Input
						value={accountNo2}
						name='Account No Confirm'
						autoComplete='off'
						placeholder='Confirm Account #'
						style={{ width: '9rem' }}
						onChange={(e) => setAccountNo2(e.target.value)}
					/>
				</Row>
				<Row>
					<Message style={{ maxWidth: '25rem', marginLeft: '0', marginTop: '1rem' }}>
						Two small amounts will be deposited into your account that must be verified.
					</Message>
				</Row>
				<Row style={{ height: '1rem', marginTop: '.5rem' }}>
					<Error>{message}</Error>
				</Row>
				<Row style={{ marginTop: '1rem', justifyContent: 'center' }}>
					<Button
						onClick={() => { setAddPayment(false); setMessage('') }}
						style={{ marginRight: '2rem', width: '7rem' }}
					>Cancel</Button>
					<Button onClick={addAchPayment} style={{ width: '7rem' }}>Submit</Button>
				</Row>
			</>
		)
	}

	const showAch = addPayment
		? <StyledModal
			isOpen={addPayment}
			style={modalStyles}
			contentLabel="Form"
			overlayClassName="modal-overlay"
		>
			<ModalContent style={{ minWidth: '25rem' }}>
				<Message
					style={{ fontSize: '1.2em', margin: '1rem', textAlign: 'center' }}
				><strong>{update ? 'Update' : 'Add'} Payment Method</strong></Message>
				<Row style={{ justifyContent: 'center' }}>
					<StyledSelect
						value={method}
						onChange={(selected) => setMethod(selected)}
						options={methodOptions}
						isSearchable={false}
						styles={selectStyles}
					/>
				</Row>
				{method.value === 'bank' ? achForm() : squareForm()}
			</ModalContent>
		</StyledModal>
		: <></>

	const showConfirmDeletePayment = confirmDeleteCard
		? <Modal
			isOpen={confirmDeleteCard}
			style={modalStyles}
			contentLabel="Form"
			overlayClassName="modal-overlay"
		>
			<ModalContent>
				<Message style={{ fontSize: '1.1em', textAlign: 'center' }}>
					<strong>Confirm Payment Deletion</strong>
				</Message>
				<Message style={{ fontSize: '.9em', marginTop: '1rem', textAlign: 'center' }}>
					By clicking "Delete", future payments will be cancelled with the current payment method.
					Some services provided by Pentadata may be not available.
				</Message>
				<Row style={{ justifyContent: 'center', marginTop: '1rem' }}>
					<Button onClick={() => setConfirmDeleteCard(false)} style={{ marginRight: '1rem' }}>Cancel</Button>
					<DeleteButtonInv onClick={() => { setConfirmDeleteCard(false); submitDeleteCard() }}>
						Delete
					</DeleteButtonInv>
				</Row>
			</ModalContent>
		</Modal>
		: <></>

	const showAchVerify = verifyAch
		? <StyledModal
			isOpen={verifyAch}
			style={modalStyles}
			contentLabel="Form"
			overlayClassName="modal-overlay"
		>
			<ModalContent style={{ minWidth: '25rem' }}>
				<Message
					style={{ fontSize: '1.2em', margin: '1rem', textAlign: 'center' }}
				><strong>Verify Payment Method</strong></Message>
				<Message style={{ maxWidth: '100%', textAlign: 'center' }}>Deposit amounts in cents(no decimal):</Message>
				<Row style={{ marginTop: '2rem', justifyContent: 'center' }}>
					<Input
						value={amt1}
						name='Amount 1'
						autoComplete='off'
						placeholder='1'
						style={{ width: '2rem', textAlign: 'center' }}
						onChange={(e) => setAmt1(e.target.value)}
					/>
					<Input
						value={amt2}
						name='Amount 2'
						autoComplete='off'
						placeholder='2'
						style={{ width: '2rem', textAlign: 'center', marginRight: '0' }}
						onChange={(e) => setAmt2(e.target.value)}
					/>
				</Row>
				<Row style={{ height: '1rem', marginTop: '.5rem' }}>
					<Error>{message}</Error>
				</Row>
				<Row style={{ marginTop: '2rem', justifyContent: 'center' }}>
					<Button
						onClick={() => { setVerifyAch(false); setMessage('') }}
						style={{ marginRight: '2rem', width: '7rem' }}
					>Cancel</Button>
					<Button onClick={verifyAchPayment} style={{ width: '7rem' }}>Submit</Button>
				</Row>
			</ModalContent>
		</StyledModal>
		: <></>

	const makeDefaultModal = openMakeDefaultModal
	? <StyledModal
		isOpen={openMakeDefaultModal}
		style={modalStyles}
		contentLabel="Form"
		overlayClassName="modal-overlay"
	>
		<ModalContent style={{ minWidth: '25rem' }}>
			<Message
				style={{ fontSize: '1.2em', margin: '1rem', textAlign: 'center' }}
			><strong>Make Account Default</strong></Message>
			<Row>
				Confirm that you want to make x{currentAccount.account_no}, your default account.
			</Row>
			<Row style={{ marginTop: '2rem', justifyContent: 'center' }}>
				<Button
					onClick={() => { setOpenMakeDefaultModal(false); setMessage(''); setCurrentAccount(''); }}
					style={{ marginRight: '2rem', width: '7rem' }}
				>
				Cancel
				</Button>
				<Button onClick={() => {makeDefault(currentAccount.payment_id); setOpenMakeDefaultModal(false)}} style={{ width: '7rem' }}>Submit</Button>
			</Row>
		</ModalContent>
	</StyledModal>
	: <></>

	// const showPaymentMethod = () => {
	// 	if (!defaultBilling) {
	// 		return (
	// 			<Button
	// 				style={{ marginTop: '.5rem' }}
	// 				onClick={() => { setAddPayment(true); setUpdate(false)}}
	// 			>
	// 				Add
	// 			</Button>
	// 		)
	// 	}

	// 	if (defaultBilling.type === 'bank') {
	// 		return (
	// 			<>
	// 				{!defaultBilling.verified
	// 					? <Button
	// 						style={{ marginTop: '.5rem' }}
	// 						onClick={() => setVerifyAch(true)}
	// 					>
	// 						Verify
	// 						</Button>
	// 					: <Description>
	// 						Bank Account: {defaultBilling.account_no}
	// 					</Description>
	// 				}
	// 			</>
	// 		)
	// 	}

	// 	return (
	// 		<Row>
	// 			Card: x{defaultBilling.last_four}
	// 			<IconLink 
	// 				onClick={() => { 
	// 					setUpdate(true); 
	// 					setAddPayment(true); 
	// 					setUpdate(true); 
	// 					setMethod({ value: 'card', label: 'Card' });
	// 				}}
	// 			>
	// 			<FontAwesomeIcon icon={faEdit} /></IconLink>
	// 		</Row>
	// 	)
	// }

	const showPayDate = () => {
		if (!dashboardData) {
			return ''
		}
		if (!dashboardData.subscription) {
			return 'Payment not scheduled'
		}
		if (!dashboardData.subscription.next_pay_date) {
			return 'Payment not scheduled'
		}
		return (
			<>{dashboardData.subscription.next_pay_date}</>
		)
	}

	const showPayAmount = () => {
		if (!dashboardData) {
			return ''
		}
		if (!dashboardData.subscription) {
			return 'Payment not scheduled'
		}
		if (!dashboardData.subscription.next_pay_charge && dashboardData.subscription.next_pay_charge !== 0) {
			return 'Payment not scheduled'
		}
		return (
			<>${dashboardData.subscription.next_pay_charge}</>
		)
	}

	const showBilling = () => {
		if (!dashboardData) {
			return <LoadingContainer><Loading type={'spin'} color={'#424242'} height={30} width={30} /></LoadingContainer>
		}

		return (
			<>
				<CardTitle><Icon><FontAwesomeIcon icon={faFileInvoiceDollar} /></Icon>Billing</CardTitle>
				<BillingRow style={{ minHeight: '4rem' }}>
					<Column>
						<Label>PAYMENT METHOD</Label>
						<Row>
						<Button
							style={{ marginTop: '.5rem' }}
							onClick={() => { setAddPayment(true); setUpdate(false)}}
						>
							Add
						</Button>
						</Row>
					</Column>
				</BillingRow>
				<BillingRow style={{ minHeight: '4rem' }}>
					<Column>
						<Label>NEXT PAY DATE</Label>
						<Row>
							<Description>
								{showPayDate()}
							</Description>
						</Row>
					</Column>
					<Column>
						<Label>NEXT PAY AMOUNT</Label>
						<Row>
							<Description>
								{showPayAmount()}
							</Description>
						</Row>
					</Column>
				</BillingRow>
			</>
		)
	}

	const cardTable = () => {
		if (!dashboardData.billing){
			return <NoDataTitle>No cards enrolled</NoDataTitle>
		}

		let cards = dashboardData.billing.filter((x) => { return x.type === "card"})
		if(!cards || cards.length === 0){
			return <NoDataTitle>No cards enrolled</NoDataTitle>
		}

		return <table>
		<thead>
			<tr>
			<th>Last 4</th>
			<th className="m-iX m-iPlus">Exp. Date</th>
			<StyledTh className="m-iX m-iPlus">Remove</StyledTh>
			</tr>
		</thead>
		<tbody>
			{cards.map((card) => (
				<tr key={`c-enroll-${card.payment_id}`}>
				<td><CardTd>{`x${card.last_four}`}</CardTd></td>
				<td className="m-iX m-iPlus">{card.exp_month + "/" + card.exp_year}</td>
				<StyledTd className="m-iX m-iPlus">
					<TrashIcon onClick={() => {setCurrentCard(card); setOpenCardModalConfirm(true)}}>
						<FontAwesomeIcon icon={faTrashAlt} />
					</TrashIcon>
				</StyledTd>
				</tr>
			))}
		</tbody>
		</table>
	}

	const invoiceTable = () => {
		if ((!dashboardData.invoices) || dashboardData.invoices.length === 0){
			return <NoDataTitle>No invoices yet</NoDataTitle>
		}
		let invoices = dashboardData.invoices;
		return <table>
			<thead>
				<tr>
				<th>Date</th>
				<th>Amount</th>
				<th>Download</th>
				</tr>
			</thead>
			<tbody>
				{invoices.map((x) => (
					<tr key={`${x.invoice_id}`}>
					<td><CardTd>{`${x.date}`}</CardTd></td>
					<td><CardTd>{`${x.amount} ${x.currency}`}</CardTd></td>
					<td><CardTd>{
						x.url ? <a href={x.url}>link</a> : "PENDING"
					}
					</CardTd></td>
					</tr>
				))}
			</tbody>
		       </table>

	}

	const achTable = () => {
		if (!dashboardData.billing){
			return <NoDataTitle>No banks enrolled</NoDataTitle>
		}

		let banks = dashboardData.billing.filter((x) => { return x.type === "bank"})

		if(!banks || banks.length === 0){
			return <NoDataTitle>No banks enrolled</NoDataTitle>
		}

		return <table>
			<thead>
				<tr>
				<th>Account Number</th>
				<StyledTh className="m-iX m-iPlus">Verified</StyledTh>
				<StyledTh className="m-iX m-iPlus">Default</StyledTh>
				<StyledTh className="m-iX m-iPlus">Remove</StyledTh>
				</tr>
			</thead>
			<tbody>
				{banks.map((bank) => (
					<tr key={`c-enroll-${bank.payment_id}`}>
					<td><CardTd>{`x${bank.account_no}`}</CardTd></td>
					<StyledTd className="m-iX m-iPlus">
						{
						bank.verified 
						? <FontAwesomeIcon style={{"color":"green"}}icon={faCheck} /> 
						: <Button onClick={() => {setCurrentAccount(bank); setVerifyAch(true);}}>Verify</Button>
						}
					</StyledTd>
					<StyledTd className="m-iX m-iPlus">
					{
						bank.verified 
						?
							bank.default 
							? <FontAwesomeIcon style={{"color":"green"}}icon={faCheck} /> 
							: <Button onClick={() => { setCurrentAccount(bank); setOpenMakeDefaultModal(true)}}>Make Default</Button>
						:	null
						}
					</StyledTd>
					<StyledTd className="m-iX m-iPlus">
						{
						!bank.default 
						? <TrashIcon onClick={() => {setCurrentAccount(bank); setOpenAccountModalConfirm(true)}}>
							<FontAwesomeIcon icon={faTrashAlt} />
						</TrashIcon>
						: <TrashIcon onClick={() => {setMessage('Please add another default payment to remove.'); setAlert({ type: 0 })}}>
							<FontAwesomeIcon icon={faTrashAlt} />
						</TrashIcon>
						}
					</StyledTd>
					</tr>
				))}
			</tbody>
		</table>

	}

	const showModalCardConfirm = openCardModalConfirm && currentCard
    ? <StyledModal
        isOpen={openCardModalConfirm}
        style={modalStyles}
        contentLabel="Notification"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
			<Message
				style={{ fontSize: '1.2em', marginBottom: '1rem', textAlign: 'center' }}
			>
				<strong>Confirm Removal</strong>
			</Message>
			<Row>
				{currentCard && <>Are you sure you want to remove x{currentCard.last_four}?</>}
			</Row>
			<Row style={{ justifyContent: 'center', marginTop: '1rem' }}>
				<Button style={{marginRight: '1rem'}} onClick={() => {setOpenCardModalConfirm(false); setMessage(''); setCurrentCard(null);}}>Cancel</Button>
				{currentCard && <Button onClick={() => {setOpenCardModalConfirm(false); deleteCard(currentCard.payment_id)}}>Remove</Button>}
			</Row>
        </ModalContent>
      </StyledModal>
    : <></>

	const showModalAccountConfirm = openAccountModalConfirm && currentAccount
    ? <StyledModal
        isOpen={openAccountModalConfirm}
        style={modalStyles}
        contentLabel="Notification"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
		<Message
				style={{ fontSize: '1.2em', marginBottom: '1rem', textAlign: 'center' }}
			>
				<strong>Confirm Removal</strong>
			</Message>
          <Message style={{minWidth: '12rem', maxWidth: '21rem'}}>
            {currentAccount && <>Are you sure you want to remove x{currentAccount.account_no}?</>}
          </Message>
          <Row style={{ justifyContent: 'center', marginTop: '1rem' }}>
            <Button style={{marginRight: '1rem'}} onClick={() => {setOpenAccountModalConfirm(false); setMessage(''); setCurrentAccount(null);}}>Cancel</Button>
            {currentAccount && <Button onClick={() => {setOpenAccountModalConfirm(false); deleteAccount(currentAccount.payment_id)}}>Remove</Button>}
          </Row>
        </ModalContent>
      </StyledModal>
    : <></>

	return (
		<Container>
			<SideNav view={view} handleView={handleView} />
			<Content>
				{showBilling()}
				<CardTitle><Icon><FontAwesomeIcon icon={faUniversity} /></Icon>Accounts</CardTitle>
				{achTable()}
				<CardTitle><Icon><FontAwesomeIcon icon={faCreditCard} /></Icon>Cards</CardTitle>
				{cardTable()}
				<CardTitle><Icon><FontAwesomeIcon icon={faFileInvoiceDollar} /></Icon>Invoices</CardTitle>
				{invoiceTable()}
				<Modal
					isOpen={openModalNotification}
					style={modalStyles}
					contentLabel="Notification"
					overlayClassName="modal-overlay"
				>
					<ModalContent style={{padding: '0 1rem'}}>
					<Message style={{minWidth: '17rem', maxWidth: '21rem', margin: '1rem 0'}}>
						{message}
					</Message>
					<Row>	
						<Button onClick={() => {setOpenModalNotification(false); setMessage('');}}>Close</Button>
					</Row>
					</ModalContent>
				</Modal>
				<Modal
					isOpen={openModalErr}
					style={modalStyles}
					contentLabel="Notification"
					overlayClassName="modal-overlay"
				>
					<ModalContent>
					<Message style={{maxWidth: '21rem'}}>
						An error occured please contact customer support using our form. &nbsp;
					</Message>
					{modalMessage && <Message style={{maxWidth: '21rem'}}>{modalMessage}</Message>}
					<Row>
						<Button onClick={() => {setOpenModalErr(false); setModalMessage('')}}>Close</Button>
					</Row>
					</ModalContent>
				</Modal>
				{showAch}
				{showAchVerify}
				{showConfirmDeletePayment}
				{showModalCardConfirm}
				{showModalAccountConfirm}
				{makeDefaultModal}
			</Content>
		</Container>
	);
}

export default isLoading(Billing);

const StyledTh = styled.th`
  text-align: center;
  vertical-align: center;
`

const CardTd = styled.span`
  text-transform: none;
`

const TrashIcon = styled.span`
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.error };
  }
`

const NoDataTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.4em;
  font-weight: 400;
  align-items: center;
  padding: .5rem auto;
  justify-content: center;
`

const StyledTd = styled.td`
  text-align: center;
  vertical-align: center;
`

const Container = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
`

const Content = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 3rem;
	${({ theme }) => theme.animations.fadeIn};

	@media (max-width: 768px) {
		height: auto;
		min-height: 100vh;
		margin: auto;
	}

	@media (max-width: 414px) {
		margin: auto;
		padding: 2rem 0 0 0;
	}
`

const Icon = styled.div`
	padding: .3rem auto;
	margin-right: 1rem;
	color: ${({ theme }) => theme.colors.primary};
`

const CardTitle = styled.div`
	display: flex;
	color: ${({ theme }) => theme.colors.text};
	font-size: 1.6em;
	font-weight: 500;
	align-items: center;
	padding: .5rem auto;
`

const LoadingContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
`

const Button = styled.button`
	color: #fff;
	border: 1px solid ${({ theme }) => theme.colors.primary};
	background-color: ${({ theme }) => theme.colors.primary};
	height: 2rem;
	min-width: 7rem;
	font-size: 1em;
	font-weight: 700;
	font-size: .9em;

	:hover {
		background-color: #fff;
		color: ${({ theme }) => theme.colors.primary};
	};

	i {
		margin-right: .5rem;
	}
`

const BillingRow = styled.div`
	display: flex;
	margin: 1.5rem 0 1.5rem 0;
	min-height: 3rem;
	@media (max-width: 414px) {
		margin-left: 3rem;
	}
`

const Column = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 25rem;

	@media (max-width: 768px) {
		min-width: 10rem;
	}

`

const Label = styled.label`
	font-size: 1.1em;
	color: ${({ theme }) => theme.colors.textSecondary};
`

// const Description = styled.label`
// 	font-size: 1.1em;
// 	color: ${({ theme }) => theme.colors.text};
// `

const StyledModal = styled(Modal)`
	position: absolute;
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	border: 1px solid rgb(204, 204, 204);
	background: rgb(255, 255, 255);
	overflow: auto;
	border-radius: 4px;
	outline: none;
	padding: 20px;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	@media (max-width: 414px) {
		width: 85%;
	}
`

const ModalContent = styled.div`
	${({ theme }) => theme.animations.fadeUp};
	min-width: 17rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-left: 1rem;
	padding-right: 1rem;
`

const Message = styled.div`
	text-align: left;
	width: 100%;
	max-width: 19rem;
	margin: auto 1rem;

	@media (max-width: 414px) {
		max-width: 15rem
		width: 100%;
	}
`

const Input = styled.input`
	padding: .4rem .75rem;
	height: 1.5rem;
	margin-right: 1rem;
	text-align: left;
	font-size: .9em;
`

const Row = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
`

const Error = styled.div`
	text-align: center;
	font-size: .9em;
	color: ${({ theme }) => theme.colors.error};
	width: 100%;
`

const StyledSelect = styled(Select)`
	width: 8rem;
	div {
		min-height: 1.3rem;
	}
	option {
		vertical-align: center;
	}
`

const CardInput = styled.div`
	text-align: left;
	span {
		margin-left: .2rem;
	}
`

const Li = styled.li`
	color: ${({ theme }) => theme.colors.error};
	font-size: .9em;
	padding-left: 1rem;
	max-width: 20rem;
`

// const IconLink = styled.div`
//   font-weight: 500;
//   font-size: .9em;
//   color: ${({ theme }) => theme.colors.textSecondary};
//   cursor: pointer;
//   margin-left: 1rem;

//   :hover {
//     color: ${({ theme }) => theme.colors.primary};
//   }
// `

// const IconLinkD = styled.div`
//   font-weight: 500;
//   font-size: .9em;
//   color: ${({ theme }) => theme.colors.textSecondary};
//   cursor: pointer;
//   margin-left: 1rem;

//   :hover {
//     color: ${({ theme }) => theme.colors.error};
//   }
// `

const DeleteButtonInv = styled.button`
  color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.error};
  background-color: ${({ theme }) => theme.colors.error};
  height: 2rem;
  min-width: 7rem;
  font-size: 1em;
  font-weight: 500;
  font-size: .9em;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.error};
  };

`

const Description = styled.div`
  font-size: 1.1em;
  color: ${({ theme }) => theme.colors.text };
`
