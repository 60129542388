import React, { useState, createContext } from 'react'
import styled from 'styled-components'
import isLoading from '../common/isLoading'
import logo from '../../images/pentadata_logo_170.png'
import SidePanel from './SidePanel'
import Trans from './Trans'
import Merchant from './Merchant'
import Mcc from './Mcc'
import { SANDBOX_URL } from '../../services/utils'
import './Tutorial.css'

export const Context = createContext()

const Tutorial = ({ setLoading }) => {
    const [view, setView] = useState('trans')
    const [token , setToken] = useState('')
    const [loginResponse, setLoginResponse] = useState({
        status: '',
        data: {}
    })

    const login = async ({ email, apiKey }) => {
        if (!email || !apiKey) return
        try {
            const res = await fetch(`${SANDBOX_URL}/subscribers/login`, {
                method: 'POST',
                body: JSON.stringify({
                    email,
                    api_key: apiKey
                })
            })
            const data = await res.json()
            setLoginResponse({
                status: res.status,
                data
            })
            if (res.status === 200) {
                setToken(data.token)
            }
        }
        catch(e) {
            console.log('error', e)
        }
    }

    const renderBody = () => {
        switch(view) {
            case 'trans':
                return(
                    <Trans />
                )
            case 'merchant':
                return(
                    <Merchant />
                )
            case 'mcc':
                return(
                    <Mcc />
                )
            default:
                return null
        }
    }

    const value = {
        view,
        setView,
        token,
        setToken,
        login,
        loginResponse,
        setLoginResponse
    }

    return (
        <Context.Provider value={value}>
            <Container>
                <Content>
                    <Header>
                        <LogoContainer>
                            <Logo src={logo} />
                        </LogoContainer>
                    </Header>
                    <Row>
                        <SidePanel />
                        <Body>
                            {renderBody()}
                        </Body>
                    </Row>
                </Content>
            </Container>
        </Context.Provider>
    );
}

export default isLoading(Tutorial);

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
    table {
        text-transform: none;
    }
`

const Row = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
`

const Content = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`

const Body = styled.div`
    margin: auto;
    flex: 1;
    flex-direction: column;
    background-color: #fff;
    height: 100%;
    padding: 2rem;
`

const Header = styled.div`
    display: flex;
    width: 100%;
    height: 7rem;
    background-color: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
    z-index: 10;
`

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    width: 14rem;
`

const Logo = styled.img`
    height: 6rem;
    margin-left: 1rem;
`
