import React, { useContext } from 'react'
import styled from 'styled-components'
import { Context } from './Tutorial'

const items = [
    {
        id: 'trans',
        displayName: 'Transactionz'
    },
    {
        id: 'merchant',
        displayName: 'MerchantSight'
    },
    {
        id: 'mcc',
        displayName: 'MccMatch'
    },
]

export default function SidePanel() {
    const { view, setView } = useContext(Context)

    return (
        <Container>
            {items.map(({ id, displayName }, idx) => (
                <Row
                    key={`side-panel${id}-${idx}`}
                    onClick={() => setView(id)}
                    selected={view === id}
                >
                    {displayName}
                </Row>
            ))}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-width: 14rem;
    height: 100%;
    div:first-child {
        margin-top: 3rem;
    }
`

const Row = styled.div`
    color: ${({ theme, selected }) =>
        selected ? theme.colors.pentaPrimary : theme.colors.primaryDark };
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .5rem auto;
    background-color: #fff;
    height: 2.5rem;
    font-weight: 500;
    width: 80%;
    border: 3px solid ${({ theme, selected }) => 
        selected ? theme.colors.pentaPrimary : theme.colors.primaryDark };
    border-radius: .5rem;
    cursor: pointer;
`
