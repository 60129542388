import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import Accordion from './Accordion'
import CodeBlock from './CodeBlock'
import { Context } from './Tutorial'
import { SANDBOX_URL } from '../../services/utils'
import { AppContext } from '../../App'

export default function ProductionAccess () {
    const { token } = useContext(Context)
    const { setAlert, setMessage } = useContext(AppContext)
    const [response, setResponse] = useState({
        status: '',
        data: {}
    })

    const getProductionAccess = async () => {
        if (!token) {
            setAlert({ type: 0})
            setMessage('Please use first the Login request at the top of this page.')
            return
        }
        try {
            const res = await fetch(`${SANDBOX_URL}/subscribers/production-access`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${token}`
                })
            })
            setResponse({
                status: res.status
            })
        }
        catch(e) {
            console.log('error', e)
            setResponse({ status: 500 })
        }
    }

    const productionResponseText = () => {
        if (!response.status) {
            return <></>
        }
        if (response.status < 299) {
            return(
                <Text>
                Thank you! We received your request and will be in touch soon.
                </Text>
            )
        }
        return(
            <Text>
            A problem occurred.  Please request production access emailing <strong>support@pentadatainc.com</strong>. Thanks!
            </Text>
        )
    }


    return (
        <Accordion title='Next steps' heightRef={response}>
            <Text>
                Congratulation on completing this part of the tutorial! We hope you enjoyed it and found our API simple and effective.
                <br /><br />
                The sandbox is free to use and is designed so that you can use it in your development environment (UAT), code with it and have your program ready to get production data. In fact, the documentation for the production API is the same as for the sandbox.
                <br /><br />
                Once you are finished with the development, come back to this page and request production access clicking on the button below.
                <br /><br />
                We know that there will be additional time spent looking at the data and understanding how to use it, once you have the real data, and this is why our production access order form is based on a <strong>pay-as-you-go</strong> formula, that allows you to start experimenting with just a few cents per month.
                <br /><br />
                After you have clicked on the below button, we will reach out and send you the order form to sign digitally. Then your production API account will be ready.
            </Text>
            <InputRow style={{ margin: '', alignSelf: 'flex-end'}}>
                <Button onClick={() => getProductionAccess()}>
                    Request Production Access
                    </Button>
            </InputRow>
            {productionResponseText()}
        </Accordion>
    )

}

const InputRow = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: .5rem 1rem;
    width: 25rem;
`

const Button = styled.button`
    background-color: ${({ theme }) => theme.colors.pentaPrimary };
    min-width: 5rem;
    display: flex;
    justify-content: center;
`
const Text = styled.div`
    font-size: 1.2em;
    margin: 1rem 0;
    text-align: left;
    padding: 0 2rem;
    max-width: 60rem;
`
