import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

const Accordion = ({ initialExpand, title, text, heightRef, children }) => {
    const [active, setActive] = useState(false)
    const [height, setHeight] = useState('0px')
    const content = useRef(null)

    useEffect(() => {
        if (initialExpand) {
            setActive(true)
        }
    }, [initialExpand])

    useEffect(() => {
        if (active) {
            setActive(true)
            setHeight(`${content.current.scrollHeight}px`)
        }
    }, [active, heightRef])

    const toggle = () => {
        setActive(!active)
        setHeight(active ? '0px' : `${content.current.scrollHeight}px`)
      }

    return (
        <Container>
            <Button onClick={toggle} active={active} >
                <Title>{title}</Title>
                <Icon style={{ transform: `${active ? 'rotate(90deg)' : ''}` }}>
                    <i className="fas fa-chevron-right"></i>
                </Icon>
            </Button>
            <Body ref={content} style={{ maxHeight: `${height}` }}>
                {children}
            </Body>
        </Container>
    )
}

export default Accordion

const Container = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`

const Button = styled.button`
  background-color: ${({ active }) => active ? 'rgba(0, 76, 172, .9)' : 'rgba(0, 76, 172, .1)' };
  color: ${({ theme, active }) => active ? '#fff' : theme.colors.pentaPrimary };
  border: 2px solid rgba(0, 76, 172, .9);
  width: 100%;
  height: 2.5rem;
  padding: .2rem;
  border-radius: .5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  outline: none;
  transition: background-color 0.6s ease;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 1.3em;
  padding: .2rem .5rem;
`

const Body = styled.div`
  background-color: white;
  overflow: hidden;
  transition: max-height 0.3s ease;
  height: 100%;
`

const Icon = styled.div`
  margin-left: auto;
  margin-right: .5rem;
  font-weight: 700;
  transition: transform 0.3s ease;
`
