import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { SANDBOX_URL } from '../../services/utils'
import Accordion from './Accordion'
import CodeBlock from './CodeBlock'
import Login from './Login'
import { mccReq, formatRes } from './codeTemplates'
import { Context } from './Tutorial'
import { AppContext } from '../../App'
import ProductionAccess from './ProductionAccess'

const Mcc = () => {

    // POST - /merchants/mcc
    const [merchantText, setMerchantText] = useState('')
    const [mccResponse, setMccResponse] = useState({
        status: '',
        data: {}
    })

    const { token } = useContext(Context)
    const { setAlert, setMessage } = useContext(AppContext)

    const fetchMcc = async () => {
        if (!token) {
            setAlert({ type: 0 })
            setMessage('JWT required, please use the POST /login request to authenticate.')
            return
        }
        try {
            const res = await fetch(`${SANDBOX_URL}/merchants/mcc`, {
                method: 'POST',
                headers: new Headers({
                    'Authorization': `Bearer ${token}`
                }),
                body: JSON.stringify({
                    merchantText,
                }),
            })
            setMccResponse({
                status: res.status,
                data: await res.json()
            })
        }
        catch(e) {
            console.log('error', e)
        }
    }

    const responseMccText = () => {
        if (!mccResponse.status) {
            return <></>
        }
        if (mccResponse.status < 299) {
            return(
                <Text>
                    Perfect. The response shows you the predictions of our AI algorithm based on the input text.
                </Text>
            )
        }
        return(
            <Text>
                Something is wrong here. Check that the input is correct; it's case sensitive!
            </Text>
        )
    }

    return (
        <Container>
            <Row style={{ padding: '1rem' }}>
                <Title>MccMatch</Title>
            </Row>
    
            <Login />

            <Accordion title='POST - /merchants/mcc' heightRef={mccResponse}>
                <Text>
                    The MccMatch API is our web product that lets you categorize a merchant down to their Merchant Category Code (MCC).

                    This is commonly used in combination with Transactionz to better understand the spending habits of a consumer, and provide them with better tailored products.

                    Try it out in the sandbox using any of the following input strings.
                </Text>
                <TableContainer>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    MerchantText
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    *PPL STBX #846-LA 9864 #IY4
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    WALM 101-NYC #R54B
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    *AZ #FF9 AMZ-MKT 0001
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    TRD JO 5R3F #174 PPX
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    D975 M'MCDO #846 TR47HH B
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </TableContainer>

                <Row style={{ margin:'1rem', height: 'auto' }}>
                    <Column>
                        <InputRow>
                            <Label>merchantText:</Label>
                            <Input
                                value={merchantText}
                                onChange={(e) => setMerchantText(e.target.value)}
                                onKeyDown={e => e.key === 'Enter' && fetchMcc()}
                            />
                        </InputRow>
                    </Column>
                    <InputRow style={{ margin: '.55rem 1rem', alignSelf: 'flex-end' }}>
                        <Button onClick={() => fetchMcc()}>
                            Send
                        </Button>
                    </InputRow>
                </Row>
                <CodeBlock
                    request={mccReq({ merchantText })}
                    response={formatRes(mccResponse)}
                />
                {responseMccText()}
            </Accordion>

            <ProductionAccess />
        </Container>
    )
}

export default Mcc

const Container = styled.div`
    ${({ theme }) => theme.animations.fadeUp };
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: auto;
    width: 100%;
    padding-bottom: 2rem;
`

const Row = styled.div`
    display: flex;
    align-items: center;
`

const InputRow = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: .5rem 1rem;
    width: 25rem;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const Title = styled.div`
    font-size: 2em;
    font-weight: 500;
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
`

const Input = styled.input`
    width: 15rem;
    height: 1rem;
    font-size: 1.2em;
`

const Label = styled.label`
    font-size: 1.4em;
    margin-right: 2rem;
`

const Button = styled.button`
    background-color: ${({ theme }) => theme.colors.pentaPrimary };
    min-width: 5rem;
    display: flex;
    justify-content: center;
`
const Text = styled.div`
    font-size: 1.2em;
    margin: 1rem 0;
    text-align: left;
    padding: 0 2rem;
    max-width: 60rem;
`

const TableContainer = styled.div`
    max-width: 60rem;
    margin: 1rem 2rem;
`
