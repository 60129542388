import React from 'react';
import styled from 'styled-components';

const SideNav = ({ view, handleView }) => {
  return (
    <Container>
      <Row style={{fontWeight: `${view === 'overview' ? '700' : ''}`}}>
        <Link onClick={() => handleView('overview')}>
          <Icon><i className="fas fa-check-square"></i></Icon>Overview
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'data usage' ? '700' : ''}`}}>
        <Link onClick={() => handleView('data usage')}>
          <Icon><i className="fas fa-cloud-download-alt"></i></Icon>Data Usage
        </Link>
      </Row>
      {/* <Row style={{fontWeight: `${view === 'your apis' ? '700' : ''}`}}>
        <Link onClick={() => handleView('your apis')}>
          <Icon><i className="fas fa-network-wired"></i></Icon>Your API's
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'use cases' ? '700' : ''}`}}>
        <Link onClick={() => handleView('use cases')}>
          <Icon><i className="fas fa-code"></i></Icon>Your Use Cases
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'data sources' ? '700' : ''}`}}>
        <Link onClick={() => handleView('data sources')}>
          <Icon><i className="fas fa-filter"></i></Icon>Data Sources
        </Link>
      </Row> */}
      <Row style={{fontWeight: `${view === 'persons' ? '700' : ''}`}}>
        <Link onClick={() => handleView('persons')}>
          <Icon><i className="fas fa-users"></i></Icon>Persons
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'accounts' ? '700' : ''}`}}>
        <Link onClick={() => handleView('accounts')}>
          <Icon><i className="fas fa-university"></i></Icon>Accounts & Cards
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'merchant' ? '700' : ''}`}}>
        <Link onClick={() => handleView('merchant')}>
          <Icon><i className="fas fa-receipt"></i></Icon>MccMatch
        </Link>
      </Row>
      <Row style={{fontWeight: `${view === 'pay' ? '700' : ''}`}}>
        <Link onClick={() => handleView('pay')}>
          <Icon><i className="fas fa-money-bill-wave"></i></Icon>Pay
        </Link>
      </Row>
    </Container>
  );
}

export default SideNav;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
  min-width: 15rem;
  width: 17rem;
  background-color: ${({ theme }) => theme.colors.primary };
  color: #fff;
  padding: 2rem 1rem;
`

const Row = styled.div`
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 1rem;
`

const Link = styled.span`
  cursor: pointer;
  display: flex;
`

const Icon = styled.div`
  width: 2.5rem;
  margin-right: 0;
  margin-left: .5rem;
`
