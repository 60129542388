import React, { useContext } from 'react';
import { Context } from 'react-square-payment-form';
import styled from 'styled-components';

const CustomPayButton = ({ text }) => {
 const context = useContext(Context);

  const handleSubmit = evt => {
    evt.preventDefault();
    context.onCreateNonce();
  }

  return (
    <Button onClick={handleSubmit}>{text}</Button>
  );
}

export default CustomPayButton;

const Button = styled.button`
  color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.primary};
  height: 2rem;
  min-width: 7rem;
  font-size: 1em;
  font-weight: 700;
  font-size: .9em;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary};
  };
`