import React, { useState, useContext } from 'react'

import styled from 'styled-components'
import Loading from 'react-loading'
import logo from '../../images/pentadata_logo_170.png'

import { AppContext } from '../../App'

const Signup = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [company, setCompany] = useState('')
  const [emailSent, setEmailSent] = useState('')
  const [loading, setLoading] = useState(false)

  const { setAlert, setMessage } = useContext(AppContext)

  const submit = async () => {
    if (!email || !firstName || !lastName || !email || !company) {
      setAlert({ type: 0 })
      setMessage('Please fill out all fields.')
    }
    setLoading(true)
    try {
      const res = await fetch('https://sandbox.pentadatainc.com/sandbox-signup', {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          email: email,
          first_name: firstName + ' ' + lastName,
          company_name: company,
        })
      })
      if (res.status === 200) {
        setEmailSent(true)
      }
    }
    catch (e) {
      console.log('Error submit ', e)
    }
    finally {
      setLoading(false)
    }
  }

  const renderForm = () => {
    if (emailSent) {
      return (
        <>
          <Text>
            Thank you for your submission.
          </Text>
          <Text>
            Your request has been received, please check your email for your api keys. Check Spam Too!
          </Text>
        </>
      )
    }
    return (
      <FormContainer>
        <FormRowCombined>
          <Input
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            placeholder='First Name'
            width='5rem'
          />
          <Input
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            placeholder='Last Name'
            width='5rem'
          />
        </FormRowCombined>
        <Divider />
        <FormRow>
          <FormColumn>
            <Input
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder='Business Email'
              width='12rem'
            />
          </FormColumn>
        </FormRow>
        <Divider />
        <FormRow>
          <FormColumn>
            <Input
              value={company}
              onChange={e => setCompany(e.target.value)}
              placeholder='Company'
              width='12rem'
            />
          </FormColumn>
        </FormRow>
        <Divider />
        <ButtonRow>
          <Button onClick={() => submit()}>
            {loading ? <Loading type={'spin'} height={15} width={15} /> : 'Sign Up'}
          </Button>
        </ButtonRow>
      </FormContainer>
    )
  }

  return (
    <Container>
      <Header>
        <LogoContainer>
          <Logo src={logo} />
        </LogoContainer>
      </Header>
      <Content>
        <ColumnLeft>
          <BodyLeft>
            <Title>
              Start building today
            </Title>
            <SubText>
              Powered by the&nbsp;
              <Link onClick={() => window.open('https://www.pentadatainc.com/', '_blank')}>
                Pentadata
              </Link>
              &nbsp;API.
            </SubText>
          </BodyLeft>
        </ColumnLeft>
        <ColumnRight>
          <SignupForm>
            <FormTitle>
              API keys in your inbox
            </FormTitle>
            {renderForm()}
          </SignupForm>
        </ColumnRight>
      </Content>
    </Container>
  )
}

export default Signup

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 0 0 4rem;

  @media (max-width: 900px) {
    width: 100%;
    margin: 0;
    align-items: center;
  }
`

const Container = styled.div`
  ${({ theme }) => theme.animations.fadeIn};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
`

const Content = styled.div`
  display: flex;
  height: 100%;
  
  @media (max-width: 900px) {
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
`

const ColumnLeft = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 4rem;

  @media (max-width: 900px) {
    margin: 0;
    width: 100%;
    justify-content: center;
    height: 15rem;
    align-items: center;
  }
  @media (max-width: 576px) {
    justify-content: flex-start;
    height: auto;
  }
`

const ColumnRight = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 4rem;

  @media (max-width: 900px) {
    margin: 0;
    width: 100%;
    justify-content: center;
    height: 60%;
  }
  @media (max-width: 576px) {
    height: 70%
  }
`

const BodyLeft = styled.div`
  height: 20rem;

  @media (max-width: 900px) {
    height: auto;
    padding: 3rem 2rem;
  }
`

const SignupForm = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
  width: 25rem;
  height: 15rem;

  @media (max-width: 900px) {
    width: auto;
    height: auto;
    margin: 0;
    padding: 0 2rem;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Title = styled.div`
  width: 100%;
  font-size: 3em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text};

  @media (max-width: 900px) {
    font-size: 2.4em;
  }
  @media (max-width: 576px) {
    font-size: 1.8em;
  }
`

const FormTitle = styled.div`
  width: 100%;
  font-size: 2em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.pentaPrimary};
  margin-bottom: 1rem;

  @media (max-width: 900px) {
    font-size: 1.8em;
    text-align: center;
  }
  @media (max-width: 576px) {
    font-size: 1.4em;
  }
`

const Text = styled.div`
  font-size: .9em;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text};
  margin: .5rem auto;
  width: 100%;

  @media (max-width: 900px) {
    text-align: center;
  }
`

const SubText = styled.div`
  font-size: .9em;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text};
  width: 100%;

  @media (max-width: 900px) {
    font-size: .8em;
  }
`

const Input = styled.input`
  padding: 0 1rem;
  border-radius: 4px;
  width: ${({ width }) => width};
  font-size: .8em;
  margin-top: .2rem;

  :active, :focus {
    box-shadow: none;
    border: 1px solid #ced4da;
  }
`

const Label = styled.div`
  font-weight: 600;
  line-height: 1.1;
`

const FormRow = styled.div`
  display: flex;
  background-color: #fff;
  align-items: center;
`

const ButtonRow = styled.div`
  display: flex;
  background-color: #fff;
  align-items: center;
  margin-left: 4.5rem

  @media (max-width: 900px) {
    margin-left: auto;
  }
`

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: 'flex-end
`

const FormRowCombined = styled.div`
  display: flex;
  background-color: #fff;
  input:first-child {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  input:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
`

const Header = styled.div`
  display: flex;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
  z-index: 10;
  align-self: flex-start;

  @media (max-width: 900px) {
    justify-content: center;
  }
`

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 14rem;

  @media (max-width: 900px) {
    width: auto;
    padding: .5rem;
  }
`

const Logo = styled.img`
  height: 6rem;
  margin-left: 1rem;

  @media (max-width: 900px) {
    height: 4rem;
    margin-left: 0;
  }
`

const Divider = styled.div`
  height: 1rem;
`

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.pentaPrimary};
  min-width: 5rem;
  height: 2rem;
  border-radius: 4px;
  display: flex;
  justify-content: center;
`

const Link = styled.span`
  color: ${({ theme }) => theme.colors.pentaPrimary};
  cursor: pointer;
`
