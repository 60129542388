import React, { useState } from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import Modal from 'react-modal';
import { modalStyles } from '../../Theme';

Modal.setAppElement('#root')

const DataUsage = ({ view, handleView, dashboardData }) => {
  const [openModal, setOpenModal] = useState(false);

  const dataTable = () => {
    if (!dashboardData) {
      return(
        <NoDataTitle>No data available</NoDataTitle>
      )
    }

    return(
     <table>
       <thead>
         <tr>
           <StyledTh>Products</StyledTh>
           <StyledTh style={{textAlign: 'center'}}>Active</StyledTh>
           <StyledTh style={{textAlign: 'center'}}>Requests</StyledTh>
           <StyledTh style={{textAlign: 'center'}}>Limit</StyledTh>
         </tr>
       </thead>
       <tbody>
          <StyledTr>
            <td>Auth</td>
            <StyledTd>
              {dashboardData.products.auth.is_active && <Icon><i className="fas fa-check"></i></Icon>}
            </StyledTd>
            <StyledTd>
              {dashboardData.products.auth.monthly_count}
            </StyledTd>
            <StyledTd>
              {dashboardData.products.auth.monthly_limit}
            </StyledTd>
          </StyledTr>
          <StyledTr>
            <td>Tranzactionz</td>
            <StyledTd>
              {dashboardData.products.transactions.is_active && <Icon><i className="fas fa-check"></i></Icon>}
            </StyledTd>
            <StyledTd>
              {dashboardData.products.transactions.monthly_count}
            </StyledTd>
            <StyledTd>
              {dashboardData.products.transactions.monthly_limit}
            </StyledTd>
          </StyledTr>
          <StyledTr>
            <td>Merchant Sight</td>
            <StyledTd>
              {dashboardData.products.merchant_id.is_active && <Icon><i className="fas fa-check"></i></Icon>}
            </StyledTd>
            <StyledTd>
              {dashboardData.products.merchant_id.monthly_count}
            </StyledTd>
            <StyledTd>
              {dashboardData.products.merchant_id.monthly_limit}
            </StyledTd>
          </StyledTr>
          <StyledTr>
            <td>MCC Match</td>
            <StyledTd>
              {dashboardData.products.mcc.is_active && <Icon><i className="fas fa-check"></i></Icon>}
            </StyledTd>
            <StyledTd>
              {dashboardData.products.mcc.monthly_count}
            </StyledTd>
            <StyledTd>
              {dashboardData.products.mcc.monthly_limit}
            </StyledTd>
          </StyledTr>
          <StyledTr>
            <td>Pay Me Back</td>
            <StyledTd>
              {dashboardData.products.pay.is_active && <Icon><i className="fas fa-check"></i></Icon>}
            </StyledTd>
            <StyledTd>
              {dashboardData.products.pay.monthly_count}
            </StyledTd>
            <StyledTd>
              {dashboardData.products.pay.monthly_limit}
            </StyledTd>
          </StyledTr>
       </tbody>
     </table>
    )
  }

  return (
    <Container>
      <SideNav view={view} handleView={handleView} />
      <Content>
        <Card>
          <CardTitle><Icon><i className="fas fa-cloud-download-alt"></i></Icon>Data Usage</CardTitle>
          <CardText>
            These values are aggregated and updated several times through the day.<br/>
            For real-time information please contact us.
          </CardText>
          <TableContainer>
            {dataTable()}
          </TableContainer>
        </Card>
        <Modal
          isOpen={openModal}
          style={modalStyles}
          contentLabel="Notification"
          overlayClassName="modal-overlay"
        >
          <ModalContent>
            <ModalMessage style={{maxWidth: '21rem'}}>
              Please contact customer service at &nbsp;
              <Link onClick={() => window.open("mailto:customersupport@pentadatainc.com", "_blank")}> 
                customersupport@pentadatainc.com 
              </Link>, &nbsp;
              or use our contact form.
            </ModalMessage>
            <Row>
              <ModalButton 
                style={{width: 'auto'}}
                onClick={() => {setOpenModal(false); handleView('subscriber support')} }
              >
                Contact
              </ModalButton>
              <ModalButton onClick={() => setOpenModal(false)}>Close</ModalButton>
            </Row>
          </ModalContent>
        </Modal>
      </Content>
    </Container>
  );
}

export default DataUsage;

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.primary };
`

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 2.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21)
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.6em;
  font-weight: 700;
  align-items: center;
  padding: .5rem auto;
  margin-bottom: 1rem;
`

const CardText = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.2em;
  font-weight: 500;
  padding: .5rem;
  margin-bottom: 1rem;
`

const NoDataTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.4em;
  font-weight: 400;
  align-items: center;
  padding: .5rem auto;
  justify-content: center;
`

const TableContainer = styled.div`
  margin-top: 1rem;
  max-width: 50%;
`

const StyledTd = styled.td`
  text-align: center;
  margin: auto;
`

const StyledTh = styled.th`
  color: ${({ theme }) => theme.colors.primary };
`

const StyledTr = styled.tr`
  td {
    color: ${({ theme }) => theme.colors.textSecondary }
  }
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp };
  text-align: center;
`

const ModalMessage = styled.div`
  color: ${({ theme }) => theme.colors.text };
  font-size: 1em;
  font-weight: 500;
  margin: 1.5rem 1.5rem;
`

const ModalButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary };
  font-size: 1.1em;
  width: 5rem;
  height: 2.25rem;
  margin: .5rem 1rem;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary };
    background-color: #fff;
  }
`

const Link = styled.a`
  cursor: pointer;
`