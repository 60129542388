import React from 'react';
import { ThemeProvider, css } from "styled-components";
import { getRNG } from './services/utils';
import { useMediaQuery } from 'react-responsive';

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 })
  return isDesktop ? children : null
}
export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}

export const selectStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? '0 1px 16px 0 rgba(0,0,0,.09)' : '0 1px 16px 0 rgba(0,0,0,.09)',
    borderColor: state.isFocused ? theme.colors.primary : '#ced4da',
    '&:hover': {
      borderColor: theme.colors.primary
    }
  }),
  option: (base, state) => ({
    ...base,
    margin: 'auto',
    minHeight: '1.3rem',
    textTransform: 'capitalize',
  }),
  menuList: (base, state) => ({
    ...base,
    maxHeight: '10rem',
    textTransform: 'capitalize',
  }),
  menu: (base, state) => ({
    ...base,
  })
};

const fadeIn = css`
  opacity: 0;
  visibility: hidden;
  animation: 500ms ease-out 0s 1 fadeIn forwards;
  @keyframes fadeIn {
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const fadeOut = css`
  opacity: 1;
  visibility: visible;
  animation: 200ms ease-out 0s 1 fadeOut forwards;
  @keyframes fadeOut {
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

export const fadeUp = css`
  opacity: 0;
  visibility: hidden;
  animation: 200ms ease-out 0s 1 fadeUp forwards;
  transform: translate(0, 20px);
  @keyframes fadeUp {
    100% {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }
  }
`;

const fadeLeft = css`
  opacity: 0;
  visibility: hidden;
  animation: 200ms ease-out 0s 1 fadeLeft forwards;
  transform: translate(20px, 0);
  @keyframes fadeLeft {
    100% {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }
  }
`;

const fadeRight = css`
  opacity: 0;
  visibility: hidden;
  animation: 200ms ease-out 0s 1 fadeRight forwards;
  transform: translate(-20px, 0);
  @keyframes fadeRight {
    100% {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }
  }
`;

const fadeDown = css`
  opacity: 0;
  visibility: hidden;
  animation: 200ms ease-out 0s 1 fadeDown forwards;
  transform: translate(0, -20px);
  @keyframes fadeDown {
    100% {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }
  }
`;

const spin = css`
  animation: spin 2s infinite linear;
  @-webkit-keyframes spin {
    0% {
      -webkit-transform:rotate(0deg);
      transform:rotate(0deg)
    }
    100% {
      -webkit-transform:rotate(359deg);
      transform:rotate(359deg)
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform:rotate(0deg);
      transform:rotate(0deg)
    }
    100% {
      -webkit-transform:rotate(359deg);
      transform:rotate(359deg)
    }
  }
`

export const modalStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
  }
};

export const modalStylesI = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               : '5px 10px',
    zIndex                : '99999'
  }
};

export const modalStylesO = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    transform             : 'translate(-50%, -50%)',
    overflow              : 'visible'
  }
};

export const mapsInputStyle = {
  border: '1px solid #ced4da',
  width: '100%'
}

const effectList =[fadeIn, fadeDown, fadeRight, fadeUp, fadeLeft];

let randomEffect = effectList[getRNG(effectList.length)];

const theme = {
  colors: {
    primary: "#367bc1",
    secondary: "#6C757D;",
    pentaPrimary: "#004CAC",
    pentaSecondary: "#E7E7E7",
    primaryDark: "#3a3a3a",
    text: "#424242",
    textSecondary: "#868ba1",
    link: "#17A2B8",
    bold: "#238fe7",
    buttonPrimary: "#367bc1",
    buttonSecondary: "#6C757D",
    navText: "#6c757d",
    toggleOff: "#BBBBBB",
    error: "#DC3545",
    facebook: '#4267B2',
    green: '#80D28C'
  },
  fonts: ["Poppins", "sans-serif"],
  fontsNumbers: ["Rubik"],
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em"
  },
  animations: {
    fadeIn,
    fadeOut,
    fadeUp,
    fadeLeft,
    fadeRight,
    fadeDown,
    spin,
    random: randomEffect
  },
  modal: {
    modalStyles
  }
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme} randomEffect={randomEffect}>{children}</ThemeProvider>
);

export default Theme;