import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import queryString from 'query-string'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { SANDBOX_URL } from '../services/utils'
import isLoading from './common/isLoading'
import pentadata from '../images/pentadata.png'
import logo from '../images/pentadata_logo_only.png'
import bg from '../images/bankbg.jpg'

const usernames = new Set(['username-1', 'username-2', 'username-3', 'username-4'])

const SandboxBank1 = ({ setLoading, bankName, primary }) => {
    let { qstr } = useParams()
    const { signature, redirectUri } = queryString.parse(qstr)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState('')
    const [auth, setAuth] = useState(false)
    const [consent, setConsent] = useState(false)

    const shareData = async() => {
        if (!consent) return
        setLoading(true)
        try {
            const res = await fetch(`${SANDBOX_URL}/bank-callbacks`, {
                method: 'POST',
                headers: new Headers({
                    'Penta-Bank-Signature': signature
                }),
                body: JSON.stringify({
                    username
                })
            })
            //change later ?
            console.log(res.status)
        }
        catch(e) {
            console.log('Share data error', e)
        }
        finally {
            setLoading(false)
            redirect()
        }
    }

    const redirect = () => {
        if (redirectUri) {
            window.location.replace(redirectUri)
        } else {
            setAuth(false)
        }
    }

    const handleLogin = () => {
        setMessage('')
        if (!username || !password) {
            setMessage('Username and password required')
            return
        }
        if (!usernames.has(username)) {
            setMessage('Invalid credentials')
            return
        }
        setLoading(true)
        setTimeout(() => {
            setAuth(true)
            setLoading(false)
        }, 2000)
    }

    const loginForm = (
        <Login>
            <Form>
                <Column style={{margin: '3rem 4rem 0 4rem'}}>
                    <FormTitle>
                        In production this will be the bank website and Pentadata never stores username and password.
                    </FormTitle>
                    <Input
                        placeholder='Username'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <Input
                        placeholder='Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        type='password'
                    />
                    <Error>{message}</Error>
                    <Button
                        primary={primary}
                        onClick={handleLogin}
                        style={{ margin: '1rem 2rem 2rem 2rem'}}
                    >
                        Sign On
                    </Button>
                </Column>
                <Footer style={{ justifyContent: 'center' }}>
                    Use username-1, username-2, username-3, or username-4, with any password.
                </Footer>
            </Form>
        </Login>
    )

    const consentForm = (
        <Consent style={{ paddingTop: '1rem' }}>
            <Column style={{ width: '70%', alignItems: 'flex-start'}}>
                <Title>Provide Consent</Title>
                <ConsentText>
                    <ul>
                        <li>
                            You authorize and direct {bankName} to share information about yourself, your 
                            {bankName} relationship and your accounts at {bankName} with <strong>(Your Company Name)</strong>, (a "third party")
                        </li>
                        <li>
                            You should use caution and ensure that the privacy and security of your information is appropiately
                            protected by them and other third parties with whom you share your information.
                        </li>
                        <li>
                            Use of your information by the third party is governed by your agreement with them, not by {bankName}
                        </li>
                        <li>
                            You can revoke future access at anytime.
                        </li>
                    </ul>
                </ConsentText>
                <Row>
                    <Checkbox type='checkbox' value={consent} onChange={() => setConsent(!consent)}/>
                    <ConsentText style={{ fontSize: '1em' }}>
                        By clicking "Share my data", I am instructing {bankName} to allow this third party to access and
                        retrieve my information.
                    </ConsentText>
                </Row>
                <Row style={{ justifyContent: 'flex-end' }}>
                    <ConsentButtonInv primary={primary} onClick={redirect}>Cancel</ConsentButtonInv>
                    <ConsentButton primary={primary} onClick={shareData}>Share my data</ConsentButton>
                </Row>
            </Column>
            <Footer style={{ padding: '1rem 2rem .5rem 2rem', width: '100%' }}>
                <Column style={{ width: '100%' }}>
                    <Row>
                        <FontAwesomeIcon icon={faLock} />
                        <span style={{marginLeft: '.5rem'}}>Secure area</span>
                    </Row>
                    <Row style={{ marginTop: '.5rem' }}>
                        2024 {bankName}. All rights reserved.
                    </Row>
                </Column>
            </Footer>
        </Consent>
    )

    return (
        <Container>
            <Content>
                <Header primary={primary}>
                    <LogoContainer>
                        <Logo src={logo} />
                        <Column style={{ alignItems: 'flex-start' }}>
                            <LogoText src={pentadata} />
                            <HeaderText>{bankName}</HeaderText>
                        </Column>
                    </LogoContainer>
                </Header>
                {auth ? consentForm : loginForm}
            </Content>
        </Container>
    );
}

export default isLoading(SandboxBank1);

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #3a3a3a;
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
`

const Row = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin: auto;
`

const Content = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Form = styled.div`
    margin: auto;
    width: 40rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: .5rem;
    background-color: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
`

const Footer = styled.div`
    display: flex;
    align-items: center;
    min-height: 4rem;
    font-size: .9em;
    margin-bottom: 0;
    margin-top: auto;
    background-color: #F3F0ED;
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
    padding: 0 1rem;
`

const Login = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    background-image: url(${bg});
    background-size: cover;
    background-position: center;
`

const Consent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #fff;
`

const Header = styled.div`
    display: flex;
    width: 100%;
    height: 7rem;
    border-bottom: 20px solid ${({ primary }) => primary};
    background-color: #fff;
`

const LogoContainer = styled.div`
    display: flex;
    padding: .5rem 2rem;
    height: 100%;
`

const Logo = styled.img`
    margin-right: 1rem;
    height: 6rem;
`

const LogoText = styled.img`
    height: 1.5rem;
`

const HeaderText = styled.div`
    font-size: 1.5em;
    margin: .5rem .5rem .5rem .25rem;
    width: 100%;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.text};
`

const Button = styled.button`
    color: #fff;
    background-color: ${({ primary }) => primary};
    padding: .5rem 1.5rem;
    font-size: 1em;
    font-weight: 700;
    margin: 1rem;
`

const ConsentButton = styled.button`
    color: #fff;
    background-color: ${({ primary }) => primary};
    padding: .5rem 1.5rem;
    font-size: 1em;
    font-weight: 700;
    margin: 1rem;
`

const ConsentButtonInv = styled.button`
    color: ${({ primary }) => primary};
    border: 1px solid ${({ primary }) => primary};
    background-color: #fff;
    padding: .5rem 1.5rem;
    font-size: 1em;
    font-weight: 700;
    margin: 1rem;
`

const FormTitle = styled.div`
    text-align: center;
    font-size: 1.2em;
    font-weight: 700;
`

const Title = styled.div`
    font-size: 2em;
    font-weight: 700;
    color: #9A8F86;
`

const ConsentText = styled.div`
    font-size: 1.2em;
    margin: 1rem 0;
    color: #9A8F86;
    
    ul {
        padding-inline-start: 30px;
    }
    li {
        text-align: justify;
        margin-top: 1rem;
    }
`

const Input = styled.input`
    height: 2rem;
    border-color: #fff;
    border-bottom: 2px solid ${({ theme }) => theme.colors.toggleOff};
    border-radius: 0;
    font-size: 1.2em;
    width: 20rem;
    caret-color: transparent;
    margin: 1.5rem 1rem 0 1rem;

    :focus {
        box-shadow: none;
        border-color: #fff;
        border-bottom: 2px solid ${({ theme }) => theme.colors.toggleOff};
    }
`

const Checkbox = styled.input`
    transform: scale(2);
    margin: 0 1.2rem;
    :focus {
        box-shadow: none;
    }
`

const Error = styled.div`
    font-size: .9em;
    text-align: center;
    color: ${({ theme }) => theme.colors.error};
    height: 1.5rem;
    margin: .5rem;
`
