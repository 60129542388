import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import { getBaseURL } from '../../services/utils';
import Loading from 'react-loading';

const Merchant = ({ view, handleView, getToken, environment, merchantSearch, setMerchantSearch }) => {
  const [searchStr, setSearchStr] = useState('');
  const [searchResults, setSearchResults] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchMsg, setSearchMsg] = useState('Enter text string from transaction to search MCC code');

  const handleSearch = useCallback( async(searchStr) => {
    if (!searchStr) {
      setSearchMsg('Text required');
      return;
    }
    setLoading(true);
    const { token } = await getToken();
    if (!token) return;
    try {
      const res = await fetch(getBaseURL(environment) + `/merchants/mcc`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }),
        body: JSON.stringify({ merchantText: searchStr })
      })
      if (res.status === 200) {
        const { mcc } = await res.json();
        setSearchResults(mcc);
      } else {
        setSearchMsg('No results found');
      }
    }
    catch(err) {
      console.log("Error fetching mcc", err)
    }
    finally {
      setLoading(false);
    }
  }, [environment, getToken])

  useEffect(() => {
    if (merchantSearch) {
      handleSearch(merchantSearch);
      setMerchantSearch('');
    }
  }, [merchantSearch, handleSearch, setMerchantSearch])

  const showResults = () => {
    if(loading) {
      return(
        <StandAloneContainer style={{alignItems: 'flex-start', padding: '2rem'}}>
          <Loading type={'spin'} color={'#424242'} height={30} width={30}/>
        </StandAloneContainer>
      )
    }
    if(searchResults) {
      return(
        <Column>
          {Object.keys(searchResults).map((r,i) => (
            <Row key={`merchant-id-search-${i}`} style={{justifyContent: 'flex-start'}}>
              <Column>
                <Number>{r}</Number>
              </Column>
              <Column>
                <Description><Bold>MCC Code: </Bold> {searchResults[r].code}</Description>
                <Description><Bold>Category: </Bold> {searchResults[r].category}</Description>
              </Column>
            </Row>
          ))}
        </Column>
      )
    } else {
      return(
        <StandAloneContainer style={{alignItems: 'flex-start'}}>
          <NoDataText>
            {searchMsg}
          </NoDataText>
        </StandAloneContainer>
      )
    }
  }

  return (
    <Container>
      <SideNav view={view} handleView={handleView} />
      <Content>
        <Card>
          <Row style={{height: '4rem', justifyContent: 'flex-start', marginTop: '1rem'}}>
            <CardTitle><Icon><i className="fas fa-receipt"></i></Icon>MccMatch</CardTitle>
          </Row>
          <Row style={{height: '4rem', justifyContent: 'center'}}>
            <Input 
              placeholder='Search..'
              onChange={(e) => setSearchStr(e.target.value)}
              value={searchStr}
            />
            <Button onClick={() => handleSearch(searchStr)}>
              <i className="fas fa-search"></i>
            </Button>
          </Row>
          {showResults()}
        </Card>
      </Content>
    </Container>
  );
}

export default Merchant;

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Column = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const Icon = styled.div`
  padding: .3rem auto;
  margin-right: 1rem;
`

const Card = styled.div`
  ${({ theme }) => theme.animations.fadeIn };
  display: flex;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #fff;
  padding: 0rem 2.5rem 2.5rem 2.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
  height: 100%;
`

const CardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text };
  font-size: 1.8em;
  font-weight: 600;
  align-items: center;
  padding: .5rem auto;
`

const Button = styled.button`
  color: #fff;
  background-color: ${({ theme }) => theme.colors.buttonPrimary };
  font-size: 1.1em;
  text-align: center;
  width: 2rem;
  padding: .4rem;
  height: 1.9rem;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary };
    border: 1px solid ${({ theme }) => theme.colors.primary };
  }
`

const Input = styled.input`
  height: 1rem;
  width: 18rem;
  margin-right: 1rem;
`

const StandAloneContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const NoDataText = styled.div`
  font-size: 1.4em;
  margin: 2rem;
`

const Number = styled.div`
  font-size: 1.4em;
  color: ${({ theme }) => theme.colors.primary };
  font-weight: 700;
  margin-right: 1rem;
`

const Description = styled.div`
  font-size: 1.1em;
`

const Bold = styled.span`
  font-weight: 500;
`