import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import Accordion from './Accordion'
import CodeBlock from './CodeBlock'
import { Context } from './Tutorial'
import { loginReq, formatRes } from './codeTemplates'

export default function Login() {
    const [email, setEmail] = useState('')
    const [apiKey, setApiKey] = useState('')
    const { login, loginResponse } = useContext(Context)

    const responseText = () => {
        if (!loginResponse.status) {
            return <></>
        }
        if (loginResponse.status < 299) {
            return(
                <Text>
                    Great, the login is successful.
                    <br /> <br />
                    When you will integrate your application with our API, the 'token' above
                    must be sent in the 'Authentication' HTTP header.
                    <br /> <br />
                    In this tutorial, we will carry the token under the hood, so you don't have to do copy
                    and paste every time.
                    <br /> <br />
                    Let's continue!
                </Text>
            )
        }
        return(
            <Text>
                The credentials are not correct. Make sure you typed the email and key correctly, then try again.
            </Text>
        )
    }

    return (
        <Accordion title='POST - /login' initialExpand={true} heightRef={loginResponse}>
            <Text>
                To start working with the API, check your email and grab the API key that we sent.
                If you lost it, ask for a new one on <a href="/#/sandbox-signup">this page</a>.
            <br />
            </Text>
            <Row style={{ margin:'1rem', height: 'auto' }}>
                <Column>
                    <InputRow>
                        <Label>email:</Label>
                        <Input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </InputRow>
                    <InputRow>
                        <Label>api_key:</Label>
                        <Input
                            value={apiKey}
                            onChange={(e) => setApiKey(e.target.value)}
                            onKeyDown={e => e.key === 'Enter' && login({ email, apiKey })}
                        />
                    </InputRow>
                </Column>
                <InputRow style={{ margin: '.55rem 1rem', alignSelf: 'flex-end' }}>
                    <Button onClick={() => login({ email, apiKey })}>
                        Send
                    </Button>
                </InputRow>
            </Row>
            <CodeBlock
                request={loginReq({ apiKey, email })}
                response={formatRes(loginResponse)}
            />
            {responseText()}
        </Accordion>
    )
}

const Row = styled.div`
    display: flex;
    align-items: center;
`

const InputRow = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: .5rem 1rem;
    width: 25rem;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const Input = styled.input`
    width: 15rem;
    height: 1rem;
    font-size: 1.2em;
`

const Label = styled.label`
    font-size: 1.4em;
    margin-right: 2rem;
`

const Button = styled.button`
    background-color: ${({ theme }) => theme.colors.pentaPrimary };
    min-width: 5rem;
    display: flex;
    justify-content: center;
`
const Text = styled.div`
    font-size: 1.2em;
    margin: 1rem 0;
    text-align: left;
    padding: 0 2rem;
    max-width: 60rem;
`
