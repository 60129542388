import { SANDBOX_URL } from '../../services/utils'
import beautify from 'json-beautify'

export const formatRes = (responseData) =>
`Status: ${responseData.status}
${beautify(responseData.data, null, 2, 50)}
`

export const loginReq = ({ email, apiKey }) =>
`curl -XPOST ${SANDBOX_URL}/subscribers/login \\
-d '{"email": "${email}", "api_key": "${apiKey}"}'`

export const addPersonReq = ({ email, firstName, lastName }) =>
`curl -XPOST ${SANDBOX_URL}/persons \\
-H 'Authorization: Bearer (TOKEN-FROM-LOGIN)' \\
-d '{"email": "${email}", "first_name": "${firstName}", "last_name": "${lastName}"}'`

export const addAccountReq = ({ bank, redirectUri, personId }) =>
`curl -XPOST ${SANDBOX_URL}/accounts \\
-H 'Authorization: Bearer (TOKEN-FROM-LOGIN)' \\
-d '{"person_id": ${personId}, "bank": "${bank}", "redirect_uri": "${redirectUri}"}'`

export const listAccountReq = ({ personId }) =>
`curl ${SANDBOX_URL}/persons/${personId}/accounts \\
-H 'Authorization: Bearer (TOKEN-FROM-LOGIN)'`

export const listAccountTransReq = ({ accountId }) =>
`curl ${SANDBOX_URL}/accounts/${accountId}/transactions?limit=10 \\
-H 'Authorization: Bearer (TOKEN-FROM-LOGIN)'`

export const accountAchReq = ({ accountId }) =>
`curl ${SANDBOX_URL}/accounts/${accountId}/ach \\
-H 'Authorization: Bearer (TOKEN-FROM-LOGIN)'`

export const accountBalanceReq = ({ accountId }) =>
`curl ${SANDBOX_URL}/accounts/${accountId}/balance \\
-H 'Authorization: Bearer (TOKEN-FROM-LOGIN)'`

export const accountOwnerReq = ({ accountId }) =>
`curl ${SANDBOX_URL}/accounts/${accountId}/owner \\
-H 'Authorization: Bearer (TOKEN-FROM-LOGIN)'`

export const accountStatementReq = ({ accountId }) =>
`curl ${SANDBOX_URL}/accounts/${accountId}/statement \\
-H 'Authorization: Bearer (TOKEN-FROM-LOGIN)'`

export const merchantReq = ({ textstring }) =>
`curl -XPOST ${SANDBOX_URL}/accounts/merchants/sight \\
-H 'Authorization: Bearer (TOKEN-FROM-LOGIN)' \\
-d '{"textstring": "${textstring}"}'`

export const mccReq = ({ merchantText }) =>
`curl -XPOST ${SANDBOX_URL}/accounts/merchants/mcc \\
-H 'Authorization: Bearer (TOKEN-FROM-LOGIN)' \\
-d '{"merchantText": "${merchantText}"}'`

export const authenticateReq = (personId, pii) =>
`curl -XPOST ${SANDBOX_URL}/persons/${personId}/authenticate \\
-H 'Authorization: Bearer (TOKEN-FROM-LOGIN)' \\
-d '{
      "first_name": "${pii.first_name}",
      "last_name": "${pii.last_name}",
      "address": "${pii.address}",
      "city": "${pii.city}",
      "postal_code": "${pii.postal_code}",
      "state": "${pii.state}",
      "country": "${pii.country}",
      "ssn": "${pii.ssn}",
      "dob": "${pii.dob}",
      "phone": "${pii.phone}"
    }'`

export const verifyReq = (personId, fulfillmentKey, { answer1, answer2 }) =>
`curl -XPOST ${SANDBOX_URL}/persons/${personId}/verify \\
-H 'Authorization: Bearer (TOKEN-FROM-LOGIN)' \\
-d '{
      "fulfillment_key": "${fulfillmentKey}",
      "answers": [
        {
          answerId: "${answer1.answerId}",
          questionId: "${answer1.questionId}",
        },
        {
          answerId: "${answer2.answerId}",
          questionId: "${answer2.questionId}",
        }
      ],
    }'`

export const incomeReq = (personId) =>
`curl ${SANDBOX_URL}/persons/${personId}/income \\
-H 'Authorization: Bearer (TOKEN-FROM-LOGIN)'`

export const reportReq = (personId) =>
`curl ${SANDBOX_URL}/persons/${personId}/report \\
-H 'Authorization: Bearer (TOKEN-FROM-LOGIN)'`
