import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { SANDBOX_URL } from '../../services/utils'
import Accordion from './Accordion'
import CodeBlock from './CodeBlock'
import Login from './Login'
import Person from './Person'
import ProductionAccess from './ProductionAccess'
import {
    addAccountReq,
    listAccountReq,
    listAccountTransReq,
    accountAchReq,
    accountBalanceReq,
    accountOwnerReq,
    accountStatementReq,
    formatRes,
} from './codeTemplates'
import { Context } from './Tutorial'
import { AppContext } from '../../App'
import JSONbig from 'json-bigint'

const Trans = () => {

    // POST - /accounts
    const [personId, setPersonId] = useState('')
    const [bank, setBank] = useState('')
    const [redirectUri, setRedirectUri] = useState('')
    const [addAccountResponse, setAddAccountResponse] = useState({
        status: '',
        data: {}
    })
    const postAccountsResponseText = () => {
        if (!addAccountResponse.status) {
            return <></>
        }
        if (addAccountResponse.status < 299) {
            return(
                <Text>
                Perfect. Click <a href={addAccountResponse.data.url} target="_blank">here</a> to open the URL <strong>in a different window</strong>.
                <br /><br />
                To simulate the login to the bank website, use "username-1", or "username-2", or "username-3", or "username-4", with any non-empty password.
                </Text>
            )
        }
        return(
            <Text>
            You must have a typo in the input, check the Person ID and the bank name (it's case sensitive), then try again.
            </Text>
        )
    }
   
    // GET - /persons/:id/accounts
    const [listAccountPersonId, setListAccountPersonId] = useState('')
    const [listAccountResponse, setListAccountResponse] = useState({
        status: '',
        data: {}
    })

    const getPersonsAccountsResponseText = () => {
        if (! listAccountResponse.status) {
            return <></>
        }
        if (listAccountResponse.status < 299) {
            return (
                <Text>
                The response status is OK. You should see some Accounts now. If you don't,
                make sure you have completed the procedure on the bank webpage. You can try as many times as you want.
                </Text>
            )
        }
        return (
            <Text>
            There is some problem with your request. Have you copied the Person ID correctly?
            </Text>
        )
    }

    // GET - /accounts/:id/transactions
    const [transAccountId, setTransAccountId] = useState('')
    const [transactionsResponse, setTransactionsResponse] = useState({
        status: '',
        data: {}
    })
    const getAccountsTransactionsResponseText = () => {
        if (! transactionsResponse.status) {
            return <></>
        }
        if (transactionsResponse.status < 299) {
            return (
                <Text>
                The response status is OK. You should see around 10 Transactions in the response now.
                </Text>
            )
        }
        return (
            <Text>
            There is some problem with your request. Have you copied the Account ID correctly?
            </Text>
        )
    }

    // GET - /accounts/:id/ach
    const [accountId, setAccountId] = useState('')
    const [accountAchResponse, setAccountAchResponse] = useState({
        status: '',
        data: {}
    })
    const getAccountsAchResponseText = () => {
        if (! accountAchResponse.status) {
            return <></>
        }
        if (accountAchResponse.status < 299) {
            return (
                <Text>
                The response status is OK and it shows you account number and routing number.
                </Text>
            )
        }
        return (
            <Text>
            There is some problem with your request. Have you copied the Account ID correctly?
            </Text>
        )
    }

    // GET - /accounts/:id/balance
    const [balanceAccountId, setBalanceAccountId] = useState('')
    const [accountBalanceResponse, setAccountBalanceResponse] = useState({
        status: '',
        data: {}
    })
    const getAccountsBalanceResponseText = () => {
        if (! accountBalanceResponse.status) {
            return <></>
        }
        if (accountBalanceResponse.status < 299) {
            return (
                <Text>
                The response status is OK and it shows you the posted and available balance, with the date when each was updated.
                </Text>
            )
        }
        return (
            <Text>
            There is some problem with your request. Have you copied the Account ID correctly?
            </Text>
        )
    }

    // GET - /accounts/:id/owner
    const [ownerAccountId, setOwnerAccountId] = useState('')
    const [accountOwnerResponse, setAccountOwnerResponse] = useState({
        status: '',
        data: {}
    })
    const getAccountsOwnerResponseText = () => {
        if (! accountOwnerResponse.status) {
            return <></>
        }
        if (accountOwnerResponse.status < 299) {
            return (
                <Text>
                The response status is OK and it shows you name and address of the Account owner.
                </Text>
            )
        }
        return (
            <Text>
            There is some problem with your request. Have you copied the Account ID correctly?
            </Text>
        )
    }

    // GET - /accounts/:id/statement
    const [statementAccountId, setStatementAccountId] = useState('')
    const [accountStatementResponse, setAccountStatementResponse] = useState({
        status: '',
        data: {}
    })
    const [statement, setStatement] = useState(null)
    const getAccountsStatementResponseText = () => {
        if (!accountStatementResponse.status) {
            return <></>
        }
        if (accountStatementResponse.status < 299) {
            return(
                <Text>
                    Success.
                    <br /><br />
                    <a href={statement} target='_blank' rel='noopener noreferrer'>Preview</a> | &nbsp;
                    <a href={statement} download='statement.pdf'>Download</a>
                </Text>
            )
        }
        return (
                <Text>
                There is some problem with your request. Have you copied the Account ID correctly?
                </Text>
        )
    }

    const { token } = useContext(Context)
    const { setAlert, setMessage } = useContext(AppContext)

    const getHeaders = () => {
        return new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        })
    }

    const parseResponse = async (res) => {
        const text = await res.text()
        const parsed = await JSONbig.parse(text, (key, value) => {
            if (key === 'account_id') {
                // return value.toString()
                return value
            }
            return value
        })
        return parsed
        // const parsed = await JSONbig.parse(text)
        // return JSONbig.stringify(parsed)
    }

    const addAccount = async () => {
        if (!token) {
            setAlert({ type: 0 })
            setMessage('JWT required, please use the POST /login request.')
            return
        }
        try {
            const res = await fetch(`${SANDBOX_URL}/accounts`, {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({
                    person_id: parseInt(personId),
                    redirect_uri: redirectUri,
                    bank,
                }),
            })
            setAddAccountResponse({
                status: res.status,
                data: await res.json()
            })
        }
        catch(e) {
            console.log('error', e)
        }
    }

    const listAccount = async () => {
        if (!token) {
            setAlert({ type: 0 })
            setMessage('JWT required, please use the POST /login request to authenticate.')
            return
        }
        try {
            const res = await fetch(`${SANDBOX_URL}/persons/${listAccountPersonId}/accounts`, {
                method: 'GET',
                headers: getHeaders(),
            })
            setListAccountResponse({
                status: res.status,
                data: await parseResponse(res)
            })
        }
        catch(e) {
            console.log('error', e)
        }
    }

    const getAccountTransactions = async () => {
        if (!token) {
            setAlert({ type: 0 })
            setMessage('JWT required, please use the POST /login request to authenticate.')
            return
        }
        try {
            const res = await fetch(`${SANDBOX_URL}/accounts/${transAccountId}/transactions?limit=10`, {
                method: 'GET',
                headers: getHeaders(),
            })
            setTransactionsResponse({
                status: res.status,
                data: await res.json()
            })
        }
        catch(e) {
            console.log('error', e)
        }
    }

    const getAccountAch = async () => {
        if (!token) {
            setAlert({ type: 0 })
            setMessage('JWT required, please use the POST /login request to authenticate.')
            return
        }
        try {
            const res = await fetch(`${SANDBOX_URL}/accounts/${accountId}/ach`, {
                method: 'GET',
                headers: getHeaders(),
            })
            setAccountAchResponse({
                status: res.status,
                data: await res.json()
            })
        }
        catch(e) {
            console.log('error', e)
        }
    }

    const getAccountBalance = async () => {
        if (!token) {
            setAlert({ type: 0 })
            setMessage('JWT required, please use the POST /login request to authenticate.')
            return
        }
        try {
            const res = await fetch(`${SANDBOX_URL}/accounts/${balanceAccountId}/balance`, {
                method: 'GET',
                headers: getHeaders(),
            })
            setAccountBalanceResponse({
                status: res.status,
                data: await res.json()
            })
        }
        catch(e) {
            console.log('error', e)
        }
    }
    const getAccountStatement = async () => {
        if (!token) {
            setAlert({ type: 0 })
            setMessage('JWT required, please use the POST /login request to authenticate.')
            return
        }
        try {
            const res = await fetch(`${SANDBOX_URL}/accounts/${statementAccountId}/statement`, {
                method: 'GET',
                headers: getHeaders(),
            })
            const blob = await res.blob()
            const url = URL.createObjectURL(blob)
            setAccountStatementResponse({
                status: res.status,
                data: blob
            })
            setStatement(url)
        }
        catch(e) {
            console.log('error', e)
        }
    }

    const getAccountOwner = async () => {
        if (!token) {
            setAlert({ type: 0 })
            setMessage('JWT required, please use the POST /login request to authenticate.')
            return
        }
        try {
            const res = await fetch(`${SANDBOX_URL}/accounts/${ownerAccountId}/owner`, {
                method: 'GET',
                headers: getHeaders(),
            })
            setAccountOwnerResponse({
                status: res.status,
                data: await res.json()
            })
        }
        catch(e) {
            console.log('error', e)
        }
    }

    return (
        <Container>
            <Row style={{ padding: '1rem' }}>
                <Title>Welcome to Pentadata!</Title>
            </Row>
            <Row>
                <Text>
                  In this interactive tutorial you will see in action the main features of our API.
                  <br /> <br />
                  The tutorial is designed to walk you through the most common use-cases and data-flow process.
                  Follow the instructions and get in touch if you have any issue.
                  <br /> <br />
                  Good luck!
                </Text>
            </Row>

            <Login />
            <Person />

            <Accordion title='POST - /accounts' heightRef={addAccountResponse}>
                <Text>
                    The next step is to ask the Person the permission to access data from their bank account.
                    <br /> <br />
                    To do this, we will give you a link that redirects to the bank website where the consumer can give the permissions. You have to use the Person IDs you received in the previous step, and can specify the bank and a webpage where you want the user to arrive at the end of the procedure (usually your website).
                    <br /> <br />
                    When you receive the response, open the URL in a different browser window, and <strong>come back to this window once you're done</strong>. In the sandbox use any of the following.
                </Text>
                <TableContainer>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Bank
                                </th>
                                <th>
                                    Redirect Uri
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    sandbox-bank-1
                                </td>
                                <td>
                                    Any valid web page address (even https://example.com)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    sandbox-bank-2
                                </td>
                                <td>
                                    Any valid web page address (even https://example.com)
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </TableContainer>
                <Row style={{ margin:'1rem', height: 'auto' }}>
                    <Column>
                        <InputRow>
                            <Label>person_id:</Label>
                            <Input
                                value={personId}
                                onChange={(e) => setPersonId(e.target.value)}
                            />
                        </InputRow>
                        <InputRow>
                            <Label>bank:</Label>
                            <Input
                                value={bank}
                                onChange={(e) => setBank(e.target.value)}
                            />
                        </InputRow>
                        <InputRow>
                            <Label>redirectUri:</Label>
                            <Input
                                value={redirectUri}
                                onChange={(e) => setRedirectUri(e.target.value)}
                                onKeyDown={e => e.key === 'Enter' && addAccount()}
                            />
                        </InputRow>
                    </Column>
                    <InputRow style={{ margin: '.55rem 1rem', alignSelf: 'flex-end' }}>
                        <Button onClick={() => addAccount()}>
                            Send
                        </Button>
                    </InputRow>
                </Row>
                <CodeBlock
                    request={addAccountReq({ bank, redirectUri, personId })}
                    response={formatRes(addAccountResponse)}
                />
                {postAccountsResponseText()}
            </Accordion>

            <Accordion title='GET - /persons/:id/accounts' heightRef={listAccountResponse}>
                <Text>
                    After the consumer has given permission through their bank, you can view what bank
                    Accounts were shared by them.

                    Use the same Person ID you have used so far.
                </Text>
                <Row style={{ margin:'1rem', height: 'auto' }}>
                    <Column>
                        <InputRow>
                            <Label>person_id:</Label>
                            <Input
                                value={listAccountPersonId}
                                onChange={(e) => setListAccountPersonId(e.target.value)}
                                onKeyDown={e => e.key === 'Enter' && listAccount()}
                            />
                        </InputRow>
                    </Column>
                    <InputRow style={{ margin: '.55rem 1rem', alignSelf: 'flex-end' }}>
                        <Button onClick={() => listAccount()}>
                            Send
                        </Button>
                    </InputRow>
                </Row>
                <CodeBlock
                    request={listAccountReq({ personId: listAccountPersonId })}
                    response={formatRes(listAccountResponse)}
                />
                {getPersonsAccountsResponseText()}
            </Accordion>

            <Accordion title='GET - /accounts/:id/transactions' heightRef={transactionsResponse}>
                <Text>
                    Once you know the Account IDs for this Person, use any of them to retrieve the
                    Trasactions.
                    <br /> <br />
                    Copy one of the 'account_id' values (integer) from the previous response and paste it below.
                </Text>
                <Row style={{ margin:'1rem', height: 'auto' }}>
                    <Column>
                        <InputRow>
                            <Label>account_id:</Label>
                            <Input
                                value={transAccountId}
                                onChange={(e) => setTransAccountId(e.target.value)}
                                onKeyDown={e => e.key === 'Enter' && getAccountTransactions()}
                            />
                        </InputRow>
                    </Column>
                    <InputRow style={{ margin: '.55rem 1rem', alignSelf: 'flex-end' }}>
                        <Button onClick={() => getAccountTransactions()}>
                            Send
                        </Button>
                    </InputRow>
                </Row>
                <CodeBlock
                    request={listAccountTransReq({ accountId: transAccountId })}
                    response={formatRes(transactionsResponse)}
                />
                {getAccountsTransactionsResponseText()}
            </Accordion>

            <Accordion title='GET - /accounts/:id/ach' heightRef={accountAchResponse}>
                <Text>
                    Transactions is usually the most important piece of data you want to get from an Account.
                    <br /> <br />
                    However, you can access much more data. Let's start by getting the full ACH details. Use the same
                    Account ID you used before.
                </Text>
                <Row style={{ margin:'1rem', height: 'auto' }}>
                    <Column>
                        <InputRow>
                            <Label>account_id:</Label>
                            <Input
                                value={accountId}
                                onChange={(e) => setAccountId(e.target.value)}
                                onKeyDown={e => e.key === 'Enter' && getAccountAch()}
                            />
                        </InputRow>
                    </Column>
                    <InputRow style={{ margin: '.55rem 1rem', alignSelf: 'flex-end' }}>
                        <Button onClick={() => getAccountAch()}>
                            Send
                        </Button>
                    </InputRow>
                </Row>
                <CodeBlock
                    request={accountAchReq({ accountId })}
                    response={formatRes(accountAchResponse)}
                />
                {getAccountsAchResponseText()}
            </Accordion>

            <Accordion title='GET - /accounts/:id/balance' heightRef={accountBalanceResponse}>
                <Text>
                    Transactions, ACH and now ... let's look at the balance of this Account.
                </Text>
                <Row style={{ margin:'1rem', height: 'auto' }}>
                    <Column>
                        <InputRow>
                            <Label>account_id:</Label>
                            <Input
                                value={balanceAccountId}
                                onChange={(e) => setBalanceAccountId(e.target.value)}
                                onKeyDown={e => e.key === 'Enter' && getAccountBalance()}
                            />
                        </InputRow>
                    </Column>
                    <InputRow style={{ margin: '.55rem 1rem', alignSelf: 'flex-end' }}>
                        <Button onClick={() => getAccountBalance()}>
                            Send
                        </Button>
                    </InputRow>
                </Row>
                <CodeBlock
                    request={accountBalanceReq({ accountId: balanceAccountId })}
                    response={formatRes(accountBalanceResponse)}
                />
                {getAccountsBalanceResponseText()}
            </Accordion>

            <Accordion title='GET - /accounts/:id/owner' heightRef={accountOwnerResponse}>
                <Text>
                    For any bank Account, you can also see the full name and address of its owner.
                    <br /> <br />
                    You could use this in many ways; a common use case is to verify the identity of your users.
                </Text>
                <Row style={{ margin:'1rem', height: 'auto' }}>
                    <Column>
                        <InputRow>
                            <Label>account_id:</Label>
                            <Input
                                value={ownerAccountId}
                                onChange={(e) => setOwnerAccountId(e.target.value)}
                                onKeyDown={e => e.key === 'Enter' && getAccountOwner()}
                            />
                        </InputRow>
                    </Column>
                    <InputRow style={{ margin: '.55rem 1rem', alignSelf: 'flex-end' }}>
                        <Button onClick={() => getAccountOwner()}>
                            Send
                        </Button>
                    </InputRow>
                </Row>
                <CodeBlock
                    request={accountOwnerReq({ accountId: ownerAccountId })}
                    response={formatRes(accountOwnerResponse)}
                />
                {getAccountsOwnerResponseText()}
            </Accordion>

            <Accordion title='GET - /accounts/:id/statement' heightRef={accountStatementResponse}>
                <Text>
                    Last but not least, you can download statements from a bank Account. Try it!
                </Text>
                <Row style={{ margin:'1rem', height: 'auto' }}>
                    <Column>
                        <InputRow>
                            <Label>account_id:</Label>
                            <Input
                                value={statementAccountId}
                                onChange={(e) => setStatementAccountId(e.target.value)}
                                onKeyDown={e => e.key === 'Enter' && getAccountStatement()}
                            />
                        </InputRow>
                    </Column>
                    <InputRow style={{ margin: '.55rem 1rem', alignSelf: 'flex-end' }}>
                        <Button onClick={() => getAccountStatement()}>
                            Send
                        </Button>
                    </InputRow>
                </Row>
                <CodeBlock
                    request={accountStatementReq({ accountId: statementAccountId })}
                    response={formatRes(accountStatementResponse)}
                />
                {getAccountsStatementResponseText()}
            </Accordion>

            <ProductionAccess />
        </Container>
    )
}

export default Trans

const Container = styled.div`
    ${({ theme }) => theme.animations.fadeUp };
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: auto;
    width: 100%;
    padding-bottom: 2rem;
`

const Row = styled.div`
    display: flex;
    align-items: center;
`

const InputRow = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: .5rem 1rem;
    width: 25rem;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const Title = styled.div`
    font-size: 2em;
    font-weight: 500;
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
`

const Input = styled.input`
    width: 15rem;
    height: 1rem;
    font-size: 1.2em;
`

const Label = styled.label`
    font-size: 1.4em;
    margin-right: 2rem;
`

const Button = styled.button`
    background-color: ${({ theme }) => theme.colors.pentaPrimary };
    min-width: 5rem;
    display: flex;
    justify-content: center;
`
const Text = styled.div`
    font-size: 1.2em;
    margin: 1rem 0;
    text-align: left;
    padding: 0 2rem;
    max-width: 60rem;
`

const TableContainer = styled.div`
    max-width: 60rem;
    margin: 1rem 2rem;
`
